/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  FeatureExtractionMethod,
  FeatureExtractionMethodFromJSON,
  FeatureExtractionMethodFromJSONTyped,
  FeatureExtractionMethodToJSON,
  MachineLearningMethod,
  MachineLearningMethodFromJSON,
  MachineLearningMethodFromJSONTyped,
  MachineLearningMethodToJSON,
} from "./"

/**
 *
 * @export
 * @interface ModelTaskInputs
 */
export interface ModelTaskInputs {
  /**
   *
   * @type {string}
   * @memberof ModelTaskInputs
   */
  title: string
  /**
   *
   * @type {MachineLearningMethod}
   * @memberof ModelTaskInputs
   */
  machineLearningMethod: MachineLearningMethod
  /**
   *
   * @type {FeatureExtractionMethod}
   * @memberof ModelTaskInputs
   */
  featureExtractionMethod: FeatureExtractionMethod
  /**
   *
   * @type {Array<number>}
   * @memberof ModelTaskInputs
   */
  individuIds: Array<number>
}

export function ModelTaskInputsFromJSON(json: any): ModelTaskInputs {
  return ModelTaskInputsFromJSONTyped(json, false)
}

export function ModelTaskInputsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelTaskInputs {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json["title"],
    machineLearningMethod: MachineLearningMethodFromJSON(json["machineLearningMethod"]),
    featureExtractionMethod: FeatureExtractionMethodFromJSON(json["featureExtractionMethod"]),
    individuIds: json["individuIds"],
  }
}

export function ModelTaskInputsToJSON(value?: ModelTaskInputs | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    machineLearningMethod: MachineLearningMethodToJSON(value.machineLearningMethod),
    featureExtractionMethod: FeatureExtractionMethodToJSON(value.featureExtractionMethod),
    individuIds: value.individuIds,
  }
}
