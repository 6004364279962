import { Grid, InputLabel, Typography } from "@material-ui/core"
import { FeatureExtractionMethod, MachineLearningMethod, ModelTask } from "api/timelight-api"
import AppHeader from "component/AppHeader"
import { buildUrl } from "component/AppLink"
import { ConfirmDialogButton } from "component/ConfirmDialogButton"
import { EmptyStateRedirect } from "component/EmptyStateRedirect"
import { createListSelectField } from "component/form/ListSelectField"
import { createIndividuSelectTableField, IndividuSelectTableFormState } from "component/form/IndividuSelectTableField"
import {
  createSpectralSourceSelectField,
  SpectralSourceSelectFormState,
} from "component/form/SpectralSourceSelectField"
import { createTextField, TextFormState } from "component/form/TextField"
import Loader from "component/Loader"
import PageBlock from "component/PageBlock"
import PageContainer from "component/PageContainer"
import PageContent from "component/PageContent"
import SideMenu from "component/SideMenu"
import { useSuccessMessage } from "component/SuccessMessage"
import React, { useMemo } from "react"
import { Form } from "react-final-form"
import { Prompt, useHistory } from "react-router-dom"
import { useApiClient } from "state/api"
import { AsyncHookParams, useAsyncAction } from "state/async"
import { AppRoute, useAppRoute } from "state/route"
import { featureExtractionMethods } from "./featureExtractionMethods"
import { machineLearningMethods } from "./machineLearningMethods"
import { createCalculator, DecoratorConfig } from "component/form/decorator"

interface FormState {
  title: TextFormState
  machineLearningMethod: MachineLearningMethod
  featureExtractionMethod: FeatureExtractionMethod
  individuIds: IndividuSelectTableFormState
  spectralSource: SpectralSourceSelectFormState
  sourceId: number
}

export function ModelTaskCreate() {
  const history = useHistory()
  const api = useApiClient()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const calculatorParams = useMemo(() => {
    return [
      {
        field: "spectralSource",
        updates: {
          individuIds: async (value, allValues) => {
            const individus = await api.getManyBaseIndividuControllerIndividu({
              filter: [`sourceId||$in||${value.sourceId}`],
            })
            const selectedIndividuIds = individus.data.map((i) => i.id)
            return value ? { sourceId: value.sourceId, selectedIndividuIds } : allValues.individuIds
          },
        },
      } as DecoratorConfig<FormState, "spectralSource">,
    ]
  }, [api])

  const currentRoute = useAppRoute()
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      const sources = await api.getManyBaseSpectralSourceControllerSpectralSource({ limit: 1 })
      if (sources.count === 0) {
        setIsEmpty(true)
      }
      const source = sources.data[0]
      const individus = await api.getManyBaseIndividuControllerIndividu({
        filter: [`sourceId||$in||${source.id}`],
      })
      const selectedIndividuIds = individus.data.map((i) => i.id)
      const values: FormState = {
        machineLearningMethod: MachineLearningMethod.SVM,
        featureExtractionMethod: FeatureExtractionMethod.DiscreteWaveletTransform,
        title: "Nouveau modèle",
        spectralSource: {
          sourceId: source.id,
        },
        individuIds: { sourceId: source.id, selectedIndividuIds },
        sourceId: source.id,
      }

      if (taskId) {
        const task = await api.getOneBaseModelTaskControllerModelTask({ id: taskId })

        values.title = task.title
        values.machineLearningMethod = task.machineLearningMethod
        values.featureExtractionMethod = task.featureExtractionMethod
      }
      return values
    },
    {
      shouldTrigger: true,
      taskId: currentTaskId,
    },
  )

  return (
    <>
      <PageContainer title="Modèle de ML - Nouveau Projet">
        <AppHeader>
          <Grid container direction="row" alignItems="center">
            <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
              Nouveau modèle de ML
            </Typography>
          </Grid>
        </AppHeader>
        <SideMenu />
        <PageContent>
          {isEmpty ? (
            <EmptyStateRedirect
              route={AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB}
              actionText={"Ajouter des premières données"}
              headerText={"Bienvenue dans votre espace Timelight Spectral"}
              helpText={`Pour utiliser Timelight à 100% de ses capacités, vous devez tout d'abord ajouter une première source de données.`}
            />
          ) : (
            <>
              <SuccessMessage />
              {!initialValues ? (
                <Loader />
              ) : (
                <Form<FormState>
                  decorators={[createCalculator(...calculatorParams)]}
                  onSubmit={async (values, form) => {
                    let res: ModelTask | null = null
                    const inputParams = {
                      machineLearningMethod: values.machineLearningMethod,
                      featureExtractionMethod: values.featureExtractionMethod,
                      title: values.title,
                      individuIds: values.individuIds.selectedIndividuIds,
                      sourceId: values.sourceId,
                    }

                    if (currentRoute.route === AppRoute.SPECTRAL_MODEL_TASK_UPDATE && currentTaskId) {
                      res = await api.modelTaskControllerUpdateOne({
                        modelTaskId: currentTaskId,
                        modelTaskInputs: inputParams,
                      })
                    } else {
                      res = await api.modelTaskControllerCreateOne({
                        modelTaskInputs: inputParams,
                      })
                    }
                    const taskId = res.id

                    showSuccessMessage()
                    setTimeout(() => {
                      history.push(
                        buildUrl({
                          route: AppRoute.SPECTRAL_MODEL_TASK_VIEW,
                          params: { taskId: taskId + "" },
                        }),
                      )
                    }, 1500)
                  }}
                  initialValues={initialValues}
                  validate={(values) => {
                    const errors: { [key in keyof typeof values]?: string[] } = {
                      individuIds: [],
                      title: [],
                    }

                    if (!values.title || values.title.length <= 0) {
                      errors.title?.push("Veuillez saisir un nom de modèle")
                    }

                    return errors
                  }}
                  render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Prompt
                          when={dirty && !submitting && !submitSucceeded}
                          message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                        />
                        <PageBlock title="Configuration du modèle">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <InputLabel style={{ marginRight: "1em", width: "150px" }}>Nom du modèle : </InputLabel>
                            {createTextField<FormState>({ name: "title", label: "Nom du modèle", required: true })}
                          </div>

                          <div style={{ display: "flex", alignItems: "center", marginBottom: "2em" }}>
                            <InputLabel style={{ marginRight: "1em", width: "200px" }}>
                              Méthode de machine learning
                            </InputLabel>

                            {createListSelectField<FormState, typeof machineLearningMethods[0]>({
                              name: "machineLearningMethod",
                              items: machineLearningMethods,
                            })}
                          </div>
                          <div style={{ display: "flex", alignItems: "center", marginBottom: "2em" }}>
                            <InputLabel style={{ marginRight: "1em", width: "200px" }}>
                              Méthode d'extraction de features
                            </InputLabel>

                            {createListSelectField<FormState, typeof featureExtractionMethods[0]>({
                              name: "featureExtractionMethod",
                              items: featureExtractionMethods,
                            })}
                          </div>
                        </PageBlock>
                        <PageBlock title="Selection des données">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <InputLabel style={{ marginRight: "1em", width: "142px" }}>Source de données : </InputLabel>
                            {createSpectralSourceSelectField<FormState>({ name: "spectralSource" })}
                          </div>
                          {createIndividuSelectTableField<FormState>({
                            name: "individuIds",
                            title: (
                              <InputLabel>Ajouter une dimension a utiliser pour l'entrainement du modèle</InputLabel>
                            ),
                            pageSize: 10,
                          })}
                        </PageBlock>

                        <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                      </form>
                    )
                  }}
                />
              )}
            </>
          )}
        </PageContent>
      </PageContainer>
    </>
  )
}
