import { FeatureExtractionMethod } from "api/timelight-api"

export const featureExtractionMethods: { label: string; value: FeatureExtractionMethod }[] = [
  { label: "Continuous Wavelet Transform", value: FeatureExtractionMethod.ContinuousWaveletTransform },
  { label: "Discrete Wavelet Transform", value: FeatureExtractionMethod.DiscreteWaveletTransform },
  { label: "Fast Fourier Transform", value: FeatureExtractionMethod.FFT5Bandes },
]

export const getFeatureExtractionMethodName = (methodeFE: FeatureExtractionMethod) => {
  return featureExtractionMethods.find((methode) => methode.value === methodeFE)?.label
}
