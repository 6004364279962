import { PredictionTask } from "api/timelight-api"
import { Grid, InputLabel, Typography } from "@material-ui/core"
import AppHeader from "component/AppHeader"
import { buildUrl } from "component/AppLink"
import { ConfirmDialogButton } from "component/ConfirmDialogButton"
import {
  createSpectralSourceSelectField,
  SpectralSourceSelectFormState,
} from "component/form/SpectralSourceSelectField"
import { createTextField, TextFormState } from "component/form/TextField"
import Loader from "component/Loader"
import PageBlock from "component/PageBlock"
import PageContainer from "component/PageContainer"
import PageContent from "component/PageContent"
import SideMenu from "component/SideMenu"
import { useSuccessMessage } from "component/SuccessMessage"
import React, { useMemo } from "react"
import { Form } from "react-final-form"
import { Prompt, useHistory } from "react-router-dom"
import { useApiClient } from "state/api"
import { AsyncHookParams, useAsyncAction } from "state/async"
import { AppRoute, useAppRoute } from "state/route"
import { EmptyStateRedirect } from "component/EmptyStateRedirect"
import { createIndividuSelectTableField, IndividuSelectTableFormState } from "component/form/IndividuSelectTableField"
import { createModelTaskSelectField, ModelTaskSelectFormState } from "component/form/ModelTaskSelectField"
import { createCalculator, DecoratorConfig } from "component/form/decorator"

interface FormState {
  title: TextFormState
  individuIds: IndividuSelectTableFormState
  spectralSource: SpectralSourceSelectFormState
  modelTask: ModelTaskSelectFormState
}

export function PredictionTaskCreate() {
  const history = useHistory()
  const api = useApiClient()

  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Tâche créée, cette opération peut prendre jusqu'à 5 minutes",
  })

  const calculatorParams = useMemo(() => {
    return [
      {
        field: "spectralSource",
        updates: {
          individuIds: (value, allValues) => {
            return value ? { sourceId: value.sourceId, selectedIndividuIds: [] } : allValues.individuIds
          },
        },
      } as DecoratorConfig<FormState, "spectralSource">,
    ]
  }, [])

  const currentRoute = useAppRoute()
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false)
  const currentTaskId = currentRoute.params.taskId ? parseInt(currentRoute.params.taskId, 10) : undefined
  const [{ data: initialValues }] = useAsyncAction<FormState, AsyncHookParams<{ taskId?: number }>>(
    async ({ taskId }) => {
      const sources = await api.getManyBaseSpectralSourceControllerSpectralSource({ limit: 1 })
      if (sources.count === 0) {
        setIsEmpty(true)
      }
      const modelTasks = await api.getManyBaseModelTaskControllerModelTask({ limit: 1 })
      if (modelTasks.count === 0) {
        setIsEmpty(true)
      }
      const source = sources.data[0]
      const model = modelTasks.data[0]
      const values: FormState = {
        title: "Nouvelle prédiction",
        spectralSource: {
          sourceId: source.id,
        },
        individuIds: { sourceId: source.id, selectedIndividuIds: [] },
        modelTask: {
          modelTaskId: model.id,
        },
      }

      if (taskId) {
        const task = await api.getOneBasePredictionTaskControllerPredictionTask({ id: taskId })

        values.title = task.title
      }
      return values
    },
    {
      shouldTrigger: true,
      taskId: currentTaskId,
    },
  )

  return (
    <PageContainer title="Prédiction - Nouveau Projet">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Nouvelle prédiction
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        {isEmpty ? (
          <EmptyStateRedirect
            route={AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB}
            actionText={"Ajouter des premières données"}
            headerText={"Bienvenue dans votre espace Timelight Spectral"}
            helpText={`Pour utiliser Timelight à 100% de ses capacités, vous devez tout d'abord ajouter une première source de données.`}
          />
        ) : (
          <>
            <SuccessMessage />
            {!initialValues ? (
              <Loader />
            ) : (
              <Form<FormState>
                decorators={[createCalculator(...calculatorParams)]}
                onSubmit={async (values, form) => {
                  let res: PredictionTask | null = null
                  const inputParams = {
                    title: values.title,
                    individuIds: values.individuIds.selectedIndividuIds,
                    modelId: values.modelTask.modelTaskId,
                  }

                  if (currentRoute.route === AppRoute.SPECTRAL_PREDICTION_TASK_UPDATE && currentTaskId) {
                    res = await api.predictionTaskControllerUpdateOne({
                      predictionTaskId: currentTaskId,
                      predictionTaskInputs: inputParams,
                    })
                  } else {
                    res = await api.predictionTaskControllerCreateOne({
                      predictionTaskInputs: inputParams,
                    })
                  }
                  const taskId = res.id

                  showSuccessMessage()
                  setTimeout(() => {
                    history.push(
                      buildUrl({
                        route: AppRoute.SPECTRAL_PREDICTION_TASK_VIEW,
                        params: { taskId: taskId + "" },
                      }),
                    )
                  }, 1500)
                }}
                initialValues={initialValues}
                validate={(values) => {
                  const errors: { [key in keyof typeof values]?: string[] } = {
                    individuIds: [],
                    title: [],
                  }

                  if (!values.title || values.title.length <= 0) {
                    errors.title?.push("Veuillez saisir un titre de prédiction")
                  }

                  return errors
                }}
                render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Prompt
                        when={dirty && !submitting && !submitSucceeded}
                        message={() => `Vous perdrez vos modifications si vous changez de page maintenant`}
                      />
                      <PageBlock title="Configuration de la prédiction">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InputLabel style={{ marginRight: "1em", width: "150px" }}>
                            Titre de la prédiction :{" "}
                          </InputLabel>
                          {createTextField<FormState>({
                            name: "title",
                            label: "Titre de la prédiction",
                            required: true,
                          })}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InputLabel style={{ marginRight: "1em", width: "142px" }}>Modèle : </InputLabel>
                          {createModelTaskSelectField<FormState>({ name: "modelTask", onlyValid: true })}
                        </div>
                      </PageBlock>
                      <PageBlock title="Selection des données">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InputLabel style={{ marginRight: "1em", width: "142px" }}>Source de données : </InputLabel>
                          {createSpectralSourceSelectField<FormState>({ name: "spectralSource" })}
                        </div>
                        {createIndividuSelectTableField<FormState>({
                          name: "individuIds",
                          title: <InputLabel>Ajouter un individu a utiliser pour la prédiction</InputLabel>,
                          pageSize: 10,
                        })}
                      </PageBlock>

                      <ConfirmDialogButton disabled={!valid} isSubmitting={submitting} onSubmit={handleSubmit} />
                    </form>
                  )
                }}
              />
            )}
          </>
        )}
      </PageContent>
    </PageContainer>
  )
}
