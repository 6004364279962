import { Tooltip, CardActionArea } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import { makeStyles, Theme } from "@material-ui/core/styles"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import clsx from "clsx"
import React from "react"
import SourceListIcon from "@material-ui/icons/DataUsage"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import ModelCreateIcon from "@material-ui/icons/PlaylistAdd"
import ModelIcon from "@material-ui/icons/Timeline"
import PredictionIcon from "@material-ui/icons/Adjust"
import { useSideMenuToggle } from "state/side-menu"
import { AnyChildren } from "../react-type-helpers"
import { AppRoute, useAppRoute } from "../state/route"
import AppLink from "./AppLink"
import Logo from "./Logo"
import { useOnboardingState } from "../state/onboarding"

export const drawerWidth = 240

function TooltipIfClosed({ open, title, children }: { open: boolean; title: string; children: AnyChildren }) {
  return open ? (
    <>{children}</>
  ) : (
    <Tooltip title={title} placement="right">
      <span>{children}</span>
    </Tooltip>
  )
}

const useStyles = makeStyles<Theme, { open: boolean }>((theme) => {
  return {
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      overflowY: "visible",
    },
    drawerPaperClose: {
      overflowX: "hidden",
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    inactiveMenu: {
      color: "#6f6f6f",
    },
    activeMenu: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    activeMenuIcon: {
      color: theme.palette.primary.main,
    },
    inactiveMenuIcon: {
      opacity: 0.7,
    },
    menuIcon: (props) => ({
      marginLeft: !props.open ? theme.spacing(1) : undefined,
      minWidth: props.open ? "30px" : undefined,
    }),
    menuText: {
      fontWeight: "inherit",
      whiteSpace: "normal",
      display: (props) => (props.open ? "block" : "none"),
    },
    subHeader: (props) => ({
      padding: !props.open ? 0 : undefined,
      textAlign: !props.open ? "center" : undefined,
    }),
    activeSubHeader: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    inactiveSubHeader: {
      color: "#5d5d5d",
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
    },
    menuIconContainer: {
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: "solid",
    },
    menuItemContainers: {
      maxHeight: "calc(100vh - 80px)", // full height minus top header height
      overflowX: "auto",
    },
    cardContent: {
      display: "flex",
      // justifyContent: "center",
      // padding: theme.spacing(4),
      color: theme.palette.primary.main,
      height: "100%",
      alignItems: "center",
      paddingLeft: "1em",
    },
    toolbarIconLogo: {
      height: "100%",
      width: "100%",
    },
  }
})

function SideMenuItem({
  title,
  open,
  itemRoutes,
  currentRoute,
  icon,
  disabled = false,
}: {
  title: string
  open: boolean
  itemRoutes: AppRoute[]
  currentRoute: AppRoute
  icon: AnyChildren
  disabled?: boolean
}) {
  const classes = useStyles({ open })
  const isCurrentItem = itemRoutes.indexOf(currentRoute) !== -1
  const content = (
    <ListItem disabled={disabled} button className={clsx(classes.inactiveMenu, isCurrentItem && classes.activeMenu)}>
      <TooltipIfClosed open={open} title={title}>
        <ListItemIcon
          className={clsx(classes.menuIcon, isCurrentItem ? classes.activeMenuIcon : classes.inactiveMenuIcon)}
        >
          <span className={classes.menuIconContainer} style={{ borderBottomWidth: isCurrentItem ? "1px" : "0px" }}>
            {icon}
          </span>
        </ListItemIcon>
      </TooltipIfClosed>
      <ListItemText primary={title} primaryTypographyProps={{ className: classes.menuText }} />
    </ListItem>
  )
  return disabled ? <>{content}</> : <AppLink route={itemRoutes[0]}>{content}</AppLink>
}

function SideMenuHeader({
  title,
  shortTitle,
  open,
  itemRoutes,
  currentRoute,
}: {
  title: string
  shortTitle: string
  open: boolean
  itemRoutes: AppRoute[]
  currentRoute: AppRoute
}) {
  const classes = useStyles({ open })
  const isCurrentItem = itemRoutes.indexOf(currentRoute) !== -1

  return (
    <ListSubheader
      className={clsx(classes.subHeader, isCurrentItem ? classes.activeSubHeader : classes.inactiveSubHeader)}
    >
      {open ? (
        isCurrentItem ? (
          <span className={classes.titleIcon}>
            <ArrowIcon />
            <span>{title}</span>
          </span>
        ) : (
          title
        )
      ) : (
        shortTitle
      )}
    </ListSubheader>
  )
}

export default function SideMenu() {
  const { open, doOpen, doClose } = useSideMenuToggle()
  const classes = useStyles({ open })
  const { route } = useAppRoute()
  const { hasSource } = useOnboardingState()

  // we are on the onboarding state, force no side menu
  if (!hasSource) {
    return <></>
  }
  const routesByModule = {
    data: [
      AppRoute.SPECTRAL_DATA_SOURCE_LIST,
      AppRoute.SPECTRAL_MODEL_TASK_LIST,
      AppRoute.SPECTRAL_MODEL_TASK_VIEW,
      AppRoute.SPECTRAL_DATA_SOURCE_VIEW,
      AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB,
      AppRoute.SPECTRAL_DATA_SOURCE_CREATE,
      AppRoute.SPECTRAL_MODEL_TASK_CREATE,
      AppRoute.SPECTRAL_PREDICTION_TASK_LIST,
      AppRoute.SPECTRAL_PREDICTION_TASK_VIEW,
      AppRoute.SPECTRAL_PREDICTION_TASK_CREATE,
      AppRoute.SPECTRAL_DATA_SOURCE_CREATE_EXAMPLE,
    ],
  }

  let currentModule: "data" | "profiles" | "forecast" | "contextImpact" | "serieSync" | "sourceCluster" | "none" =
    "none"
  for (const [moduleKey, moduleRoutes] of Object.entries(routesByModule)) {
    if (moduleRoutes.includes(route)) {
      currentModule = moduleKey as any
      break
    }
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.toolbarIconLogo}>
          <AppLink route={AppRoute.HOME}>
            <CardActionArea className={classes.cardContent}>
              <Logo />
            </CardActionArea>
          </AppLink>
        </div>
        <IconButton onClick={() => (open ? doClose() : doOpen())}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <div className={classes.menuItemContainers}>
        {currentModule === "data" && (
          <>
            <List>
              <div>
                <SideMenuHeader
                  title="Données"
                  shortTitle="Données"
                  open={open}
                  itemRoutes={[
                    AppRoute.SPECTRAL_DATA_SOURCE_LIST,
                    AppRoute.SPECTRAL_DATA_SOURCE_VIEW,
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB,
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE,
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE_EXAMPLE,
                  ]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title={`Explorer vos données`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SPECTRAL_DATA_SOURCE_LIST, AppRoute.SPECTRAL_DATA_SOURCE_VIEW]}
                  open={open}
                  icon={<SourceListIcon />}
                />

                <SideMenuItem
                  title={`Ajouter des données`}
                  currentRoute={route}
                  itemRoutes={[
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB,
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE_EXAMPLE,
                    AppRoute.SPECTRAL_DATA_SOURCE_CREATE,
                  ]}
                  open={open}
                  icon={<AddCircleOutlineIcon />}
                />

                <SideMenuHeader
                  title="Modèles"
                  shortTitle="Modèles"
                  open={open}
                  itemRoutes={[
                    AppRoute.SPECTRAL_MODEL_TASK_LIST,
                    AppRoute.SPECTRAL_MODEL_TASK_VIEW,
                    AppRoute.SPECTRAL_MODEL_TASK_CREATE,
                  ]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title={`Explorer vos modèles`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SPECTRAL_MODEL_TASK_LIST, AppRoute.SPECTRAL_MODEL_TASK_VIEW]}
                  open={open}
                  icon={<ModelIcon />}
                />

                <SideMenuItem
                  title={`Créer un modèle`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SPECTRAL_MODEL_TASK_CREATE]}
                  open={open}
                  icon={<ModelCreateIcon />}
                />

                <SideMenuHeader
                  title="Prédictions"
                  shortTitle="Prédictions"
                  open={open}
                  itemRoutes={[
                    AppRoute.SPECTRAL_PREDICTION_TASK_LIST,
                    AppRoute.SPECTRAL_PREDICTION_TASK_VIEW,
                    AppRoute.SPECTRAL_PREDICTION_TASK_CREATE,
                  ]}
                  currentRoute={route}
                />

                <SideMenuItem
                  title={`Explorer vos prédictions`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SPECTRAL_PREDICTION_TASK_LIST, AppRoute.SPECTRAL_PREDICTION_TASK_VIEW]}
                  open={open}
                  icon={<PredictionIcon />}
                />

                <SideMenuItem
                  title={`Lancer une prédiction`}
                  currentRoute={route}
                  itemRoutes={[AppRoute.SPECTRAL_PREDICTION_TASK_CREATE]}
                  open={open}
                  icon={<ModelCreateIcon />}
                />
              </div>
            </List>
          </>
        )}
      </div>
    </Drawer>
  )
}
