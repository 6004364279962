/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManySpectralSourceDimensionResponseDto,
  GetManySpectralSourceDimensionResponseDtoFromJSON,
  GetManySpectralSourceDimensionResponseDtoToJSON,
  SpectralSourceDimension,
  SpectralSourceDimensionFromJSON,
  SpectralSourceDimensionToJSON,
  SpectralSourceDimensionInputDto,
  SpectralSourceDimensionInputDtoFromJSON,
  SpectralSourceDimensionInputDtoToJSON,
} from "../models"

export interface GetManyBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface SpectralSourceDimensionControllerCreateSourceDimensionRequest {
  spectralSourceDimensionInputDto: SpectralSourceDimensionInputDto
}

/**
 * no description
 */
export class SpectralSourceDimensionApi extends runtime.BaseAPI {
  /**
   * Retrieve many SpectralSourceDimension
   */
  async getManyBaseSpectralSourceDimensionControllerSpectralSourceDimensionRaw(
    requestParameters: GetManyBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest,
  ): Promise<runtime.ApiResponse<GetManySpectralSourceDimensionResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source-dimension`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetManySpectralSourceDimensionResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Retrieve many SpectralSourceDimension
   */
  async getManyBaseSpectralSourceDimensionControllerSpectralSourceDimension(
    requestParameters: GetManyBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest,
  ): Promise<GetManySpectralSourceDimensionResponseDto> {
    const response = await this.getManyBaseSpectralSourceDimensionControllerSpectralSourceDimensionRaw(
      requestParameters,
    )
    return await response.value()
  }

  /**
   * Retrieve one SpectralSourceDimension
   */
  async getOneBaseSpectralSourceDimensionControllerSpectralSourceDimensionRaw(
    requestParameters: GetOneBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest,
  ): Promise<runtime.ApiResponse<SpectralSourceDimension>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSpectralSourceDimensionControllerSpectralSourceDimension.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source-dimension/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpectralSourceDimensionFromJSON(jsonValue))
  }

  /**
   * Retrieve one SpectralSourceDimension
   */
  async getOneBaseSpectralSourceDimensionControllerSpectralSourceDimension(
    requestParameters: GetOneBaseSpectralSourceDimensionControllerSpectralSourceDimensionRequest,
  ): Promise<SpectralSourceDimension> {
    const response = await this.getOneBaseSpectralSourceDimensionControllerSpectralSourceDimensionRaw(requestParameters)
    return await response.value()
  }

  /**
   */
  async spectralSourceDimensionControllerCreateSourceDimensionRaw(
    requestParameters: SpectralSourceDimensionControllerCreateSourceDimensionRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.spectralSourceDimensionInputDto === null ||
      requestParameters.spectralSourceDimensionInputDto === undefined
    ) {
      throw new runtime.RequiredError(
        "spectralSourceDimensionInputDto",
        "Required parameter requestParameters.spectralSourceDimensionInputDto was null or undefined when calling spectralSourceDimensionControllerCreateSourceDimension.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source-dimension`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpectralSourceDimensionInputDtoToJSON(requestParameters.spectralSourceDimensionInputDto),
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async spectralSourceDimensionControllerCreateSourceDimension(
    requestParameters: SpectralSourceDimensionControllerCreateSourceDimensionRequest,
  ): Promise<void> {
    await this.spectralSourceDimensionControllerCreateSourceDimensionRaw(requestParameters)
  }
}
