/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface IndividuInputDto
 */
export interface IndividuInputDto {
  /**
   *
   * @type {string}
   * @memberof IndividuInputDto
   */
  classe: string
  /**
   *
   * @type {string}
   * @memberof IndividuInputDto
   */
  code: string | null
  /**
   *
   * @type {number}
   * @memberof IndividuInputDto
   */
  sourceId: number | null
}

export function IndividuInputDtoFromJSON(json: any): IndividuInputDto {
  return IndividuInputDtoFromJSONTyped(json, false)
}

export function IndividuInputDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividuInputDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    classe: json["classe"],
    code: json["code"],
    sourceId: json["sourceId"],
  }
}

export function IndividuInputDtoToJSON(value?: IndividuInputDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    classe: value.classe,
    code: value.code,
    sourceId: value.sourceId,
  }
}
