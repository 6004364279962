/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  ChartDimensionDto,
  ChartDimensionDtoFromJSON,
  ChartDimensionDtoToJSON,
  GetManySpectralSourceResponseDto,
  GetManySpectralSourceResponseDtoFromJSON,
  GetManySpectralSourceResponseDtoToJSON,
  SpectralSource,
  SpectralSourceFromJSON,
  SpectralSourceToJSON,
  SpectralSourceChartBandInput,
  SpectralSourceChartBandInputFromJSON,
  SpectralSourceChartBandInputToJSON,
  SpectralSourceChartInput,
  SpectralSourceChartInputFromJSON,
  SpectralSourceChartInputToJSON,
  SpectralSourceInputDto,
  SpectralSourceInputDtoFromJSON,
  SpectralSourceInputDtoToJSON,
} from "../models"

export interface GetManyBaseSpectralSourceControllerSpectralSourceRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseSpectralSourceControllerSpectralSourceRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface SpectralSourceControllerCreateSourceRequest {
  spectralSourceInputDto: SpectralSourceInputDto
}

export interface SpectralSourceControllerGetChartDataRequest {
  spectralSourceChartInput: SpectralSourceChartInput
}

export interface SpectralSourceControllerGetChartDataBandRequest {
  spectralSourceChartBandInput: SpectralSourceChartBandInput
}

/**
 * no description
 */
export class SpectralSourceApi extends runtime.BaseAPI {
  /**
   * Retrieve many SpectralSource
   */
  async getManyBaseSpectralSourceControllerSpectralSourceRaw(
    requestParameters: GetManyBaseSpectralSourceControllerSpectralSourceRequest,
  ): Promise<runtime.ApiResponse<GetManySpectralSourceResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManySpectralSourceResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many SpectralSource
   */
  async getManyBaseSpectralSourceControllerSpectralSource(
    requestParameters: GetManyBaseSpectralSourceControllerSpectralSourceRequest,
  ): Promise<GetManySpectralSourceResponseDto> {
    const response = await this.getManyBaseSpectralSourceControllerSpectralSourceRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one SpectralSource
   */
  async getOneBaseSpectralSourceControllerSpectralSourceRaw(
    requestParameters: GetOneBaseSpectralSourceControllerSpectralSourceRequest,
  ): Promise<runtime.ApiResponse<SpectralSource>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseSpectralSourceControllerSpectralSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpectralSourceFromJSON(jsonValue))
  }

  /**
   * Retrieve one SpectralSource
   */
  async getOneBaseSpectralSourceControllerSpectralSource(
    requestParameters: GetOneBaseSpectralSourceControllerSpectralSourceRequest,
  ): Promise<SpectralSource> {
    const response = await this.getOneBaseSpectralSourceControllerSpectralSourceRaw(requestParameters)
    return await response.value()
  }

  /**
   */
  async spectralSourceControllerCreateSourceRaw(
    requestParameters: SpectralSourceControllerCreateSourceRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.spectralSourceInputDto === null || requestParameters.spectralSourceInputDto === undefined) {
      throw new runtime.RequiredError(
        "spectralSourceInputDto",
        "Required parameter requestParameters.spectralSourceInputDto was null or undefined when calling spectralSourceControllerCreateSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpectralSourceInputDtoToJSON(requestParameters.spectralSourceInputDto),
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async spectralSourceControllerCreateSource(
    requestParameters: SpectralSourceControllerCreateSourceRequest,
  ): Promise<void> {
    await this.spectralSourceControllerCreateSourceRaw(requestParameters)
  }

  /**
   * Get Data for the Spectral Source Chart
   */
  async spectralSourceControllerGetChartDataRaw(
    requestParameters: SpectralSourceControllerGetChartDataRequest,
  ): Promise<runtime.ApiResponse<Array<ChartDimensionDto>>> {
    if (
      requestParameters.spectralSourceChartInput === null ||
      requestParameters.spectralSourceChartInput === undefined
    ) {
      throw new runtime.RequiredError(
        "spectralSourceChartInput",
        "Required parameter requestParameters.spectralSourceChartInput was null or undefined when calling spectralSourceControllerGetChartData.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source/chart`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpectralSourceChartInputToJSON(requestParameters.spectralSourceChartInput),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChartDimensionDtoFromJSON))
  }

  /**
   * Get Data for the Spectral Source Chart
   */
  async spectralSourceControllerGetChartData(
    requestParameters: SpectralSourceControllerGetChartDataRequest,
  ): Promise<Array<ChartDimensionDto>> {
    const response = await this.spectralSourceControllerGetChartDataRaw(requestParameters)
    return await response.value()
  }

  /**
   * Get Data for the Spectral Source Chart with Frequency Band
   */
  async spectralSourceControllerGetChartDataBandRaw(
    requestParameters: SpectralSourceControllerGetChartDataBandRequest,
  ): Promise<runtime.ApiResponse<Array<ChartDimensionDto>>> {
    if (
      requestParameters.spectralSourceChartBandInput === null ||
      requestParameters.spectralSourceChartBandInput === undefined
    ) {
      throw new runtime.RequiredError(
        "spectralSourceChartBandInput",
        "Required parameter requestParameters.spectralSourceChartBandInput was null or undefined when calling spectralSourceControllerGetChartDataBand.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/spectral-source/chart-band`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SpectralSourceChartBandInputToJSON(requestParameters.spectralSourceChartBandInput),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChartDimensionDtoFromJSON))
  }

  /**
   * Get Data for the Spectral Source Chart with Frequency Band
   */
  async spectralSourceControllerGetChartDataBand(
    requestParameters: SpectralSourceControllerGetChartDataBandRequest,
  ): Promise<Array<ChartDimensionDto>> {
    const response = await this.spectralSourceControllerGetChartDataBandRaw(requestParameters)
    return await response.value()
  }
}
