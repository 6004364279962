/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  SpectralSourceDimension,
  SpectralSourceDimensionFromJSON,
  SpectralSourceDimensionFromJSONTyped,
  SpectralSourceDimensionToJSON,
} from "./"

/**
 *
 * @export
 * @interface GetManySpectralSourceDimensionResponseDto
 */
export interface GetManySpectralSourceDimensionResponseDto {
  /**
   *
   * @type {Array<SpectralSourceDimension>}
   * @memberof GetManySpectralSourceDimensionResponseDto
   */
  data: Array<SpectralSourceDimension>
  /**
   *
   * @type {number}
   * @memberof GetManySpectralSourceDimensionResponseDto
   */
  count: number
  /**
   *
   * @type {number}
   * @memberof GetManySpectralSourceDimensionResponseDto
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof GetManySpectralSourceDimensionResponseDto
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof GetManySpectralSourceDimensionResponseDto
   */
  pageCount: number
}

export function GetManySpectralSourceDimensionResponseDtoFromJSON(
  json: any,
): GetManySpectralSourceDimensionResponseDto {
  return GetManySpectralSourceDimensionResponseDtoFromJSONTyped(json, false)
}

export function GetManySpectralSourceDimensionResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetManySpectralSourceDimensionResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json["data"] as Array<any>).map(SpectralSourceDimensionFromJSON),
    count: json["count"],
    total: json["total"],
    page: json["page"],
    pageCount: json["pageCount"],
  }
}

export function GetManySpectralSourceDimensionResponseDtoToJSON(
  value?: GetManySpectralSourceDimensionResponseDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(SpectralSourceDimensionToJSON),
    count: value.count,
    total: value.total,
    page: value.page,
    pageCount: value.pageCount,
  }
}
