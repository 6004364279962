import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PageBlock from "component/PageBlock"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { AppRoute, useAppRoute } from "../../state/route"
import { useApiClient, usePredictionResult, usePredictionTask } from "state/api"
import { isTaskEnded, useTaskStream } from "state/tasksStream"
import TaskStatusActivity from "component/TaskStatusActivity"
import { formatDate } from "lib/date"
import { TaskActionsBlock } from "component/TaskActionsBlock"
import { PredictionResult } from "api/timelight-api"
import {
  createNumericColumnConfig,
  createTableExportCsv,
  createTextColumnConfig,
  extractColumnConfigAndExportConfig,
  MaterialTable,
} from "component/MaterialTable"
import Loader from "component/Loader"

export default function PredictionTaskView() {
  const currentRoute = useAppRoute()
  const predictionTaskId = parseInt(currentRoute.params.taskId || "-1", 10)

  // fetch page data
  const [{ data: initModelTask }] = usePredictionTask({
    shouldTrigger: predictionTaskId > 0,
    predictionTaskId,
  })
  const task = useTaskStream(initModelTask)

  const api = useApiClient()

  return (
    <PageContainer title={"prediction details"}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Détails de la prédiction
            {task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!task} title={"Détails de la prédiction: "}>
          {task && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <dl>
                <dt>Titre de la prédiction :</dt>
                <dd>
                  <strong>{task.title}</strong>
                </dd>

                <dt>Modèle utilisé :</dt>
                <dd>
                  <strong>{task.modelId}</strong>
                </dd>

                <dt>Date d'entrainement :</dt>
                <dd>
                  <strong>{formatDate(task.createdDate)}</strong>
                </dd>

                <dt>Status de la tâche</dt>
                <dd>
                  <TaskStatusActivity large={true} task={task} />
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>
        {task && isTaskEnded(task) && <PredictionResultTable taskId={task.id} />}
        {task && task.error && (
          <PageBlock title="Erreur technique">
            <pre>{task.error}</pre>
          </PageBlock>
        )}
        {task && isTaskEnded(task) && (
          <TaskActionsBlock
            deleteProps={{
              buttonText: <>Supprimer la prédiction</>,
              popinButtonText: <>Supprimer définitivement la prédiction {task.title}</>,
              popinTitle: <>Supprimer la prédiction {task.title} ?</>,
              popinWarning: (
                <>Cette action est irréversible, vous perdrez toutes les données liées à cette prédiction</>
              ),
              successMessage: `Prédiction ${task.title} supprimé définitivement`,
              redirectToAfterAction: { route: AppRoute.SPECTRAL_PREDICTION_TASK_LIST },
              doAction: async () => {
                await api.predictionTaskControllerDeleteOne({
                  predictionTaskId: task.id,
                })
              },
            }}
            updateProps={{
              buttonText: <>Mettre à jour</>,
              popinButtonText: <>Supprimer définitivement les données de la prédiction {task.title}</>,
              popinTitle: <>La mise à jour supprimera les données de la prédiction {task.title} ?</>,
              popinWarning: (
                <>Cette action est irréversible, vous perdrez toutes les données liées à cette prédiction</>
              ),
              redirectToAfterAction: {
                route: AppRoute.SPECTRAL_PREDICTION_TASK_UPDATE,
                params: { taskId: task.id + "" },
              },
              doAction: async () => {},
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}

const PredictionResultTable = React.memo(function PredictionResultTableComponent({ taskId }: { taskId: number }) {
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<PredictionResult>([
    createNumericColumnConfig({
      title: "Id",
      getValue: (row) => row.individuId,
    }),
    createTextColumnConfig({
      title: "Code",
      getTextValue: (row) => row.individuCode,
    }),
    createTextColumnConfig({
      title: "Classe",
      getTextValue: (row) => row.individuClass,
    }),
    createTextColumnConfig({
      title: "Classe Prédite",
      getTextValue: (row) => row.predictedClass,
    }),
  ])

  // fetch data
  const [{ data: predictionResults }] = usePredictionResult({
    shouldTrigger: true,
    predictionTaskId: taskId,
  })

  return (
    <>
      {predictionResults === null ? (
        <Loader />
      ) : (
        <PageBlock titleInContent={true}>
          <MaterialTable<PredictionResult>
            title={`Résultats de la prédiction: `}
            data={predictionResults}
            columns={columns}
            options={{
              exportCsv: createTableExportCsv({
                exportConfig,
                fileName: `prediction.csv`,
              }),
            }}
          />
        </PageBlock>
      )}
    </>
  )
})
