/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManyPredictionIndividuResponseDto,
  GetManyPredictionIndividuResponseDtoFromJSON,
  GetManyPredictionIndividuResponseDtoToJSON,
  PredictionIndividu,
  PredictionIndividuFromJSON,
  PredictionIndividuToJSON,
} from "../models"

export interface GetManyBasePredictionIndividuControllerPredictionIndividuRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBasePredictionIndividuControllerPredictionIndividuRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

/**
 * no description
 */
export class PredictionIndividuApi extends runtime.BaseAPI {
  /**
   * Retrieve many PredictionIndividu
   */
  async getManyBasePredictionIndividuControllerPredictionIndividuRaw(
    requestParameters: GetManyBasePredictionIndividuControllerPredictionIndividuRequest,
  ): Promise<runtime.ApiResponse<GetManyPredictionIndividuResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-individu`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyPredictionIndividuResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many PredictionIndividu
   */
  async getManyBasePredictionIndividuControllerPredictionIndividu(
    requestParameters: GetManyBasePredictionIndividuControllerPredictionIndividuRequest,
  ): Promise<GetManyPredictionIndividuResponseDto> {
    const response = await this.getManyBasePredictionIndividuControllerPredictionIndividuRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one PredictionIndividu
   */
  async getOneBasePredictionIndividuControllerPredictionIndividuRaw(
    requestParameters: GetOneBasePredictionIndividuControllerPredictionIndividuRequest,
  ): Promise<runtime.ApiResponse<PredictionIndividu>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBasePredictionIndividuControllerPredictionIndividu.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-individu/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PredictionIndividuFromJSON(jsonValue))
  }

  /**
   * Retrieve one PredictionIndividu
   */
  async getOneBasePredictionIndividuControllerPredictionIndividu(
    requestParameters: GetOneBasePredictionIndividuControllerPredictionIndividuRequest,
  ): Promise<PredictionIndividu> {
    const response = await this.getOneBasePredictionIndividuControllerPredictionIndividuRaw(requestParameters)
    return await response.value()
  }
}
