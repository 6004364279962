/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface ChartDimensionDto
 */
export interface ChartDimensionDto {
  /**
   *
   * @type {string}
   * @memberof ChartDimensionDto
   */
  dimension: string
  /**
   *
   * @type {Array<number>}
   * @memberof ChartDimensionDto
   */
  data: Array<number>
}

export function ChartDimensionDtoFromJSON(json: any): ChartDimensionDto {
  return ChartDimensionDtoFromJSONTyped(json, false)
}

export function ChartDimensionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChartDimensionDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    dimension: json["dimension"],
    data: json["data"],
  }
}

export function ChartDimensionDtoToJSON(value?: ChartDimensionDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    dimension: value.dimension,
    data: value.data,
  }
}
