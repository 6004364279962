import { useRouteMatch } from "react-router-dom"

export type AppRouteParams = {
  groupId?: string
  sourceId?: string
  taskId?: string
}

export enum AppRoute {
  EMPTY_INSTANCE = "/no-data",
  HOME = "/",

  SPECTRAL_DATA_SOURCE_LIST = "/spectral-source/mes-sources",
  SPECTRAL_DATA_SOURCE_CREATE_HUB = "/spectral-source/create",
  SPECTRAL_DATA_SOURCE_CREATE = "/spectral-source/import-json",
  SPECTRAL_DATA_SOURCE_CREATE_EXAMPLE = "/spectral-source/import-example",
  SPECTRAL_DATA_SOURCE_VIEW = "/spectral-source/:sourceId",
  SPECTRAL_MODEL_TASK_LIST = "/spectral-source/model/",
  SPECTRAL_MODEL_TASK_VIEW = "/spectral-source/model/:taskId",
  SPECTRAL_MODEL_TASK_CREATE = "/spectral-source/model/create",
  SPECTRAL_MODEL_TASK_UPDATE = "/spectral-source/model/update/:taskId",
  SPECTRAL_PREDICTION_TASK_LIST = "/spectral-source/prediction/",
  SPECTRAL_PREDICTION_TASK_VIEW = "/spectral-source/prediction/:taskId",
  SPECTRAL_PREDICTION_TASK_CREATE = "/spectral-source/prediction/create",
  SPECTRAL_PREDICTION_TASK_UPDATE = "/spectral-source/prediction/update/:taskId",

  LOGIN = "/login",
  REGISTER = "/inscription",
  AUTH_ERROR = "/auth/error",
}

export function useAppRoute(): { route: AppRoute; params: AppRouteParams } {
  const currentMatch = useRouteMatch<AppRouteParams>()

  const match = Object.entries(AppRoute)
    .map(
      // @ts-ignore
      ([key, value]) => [key, value, AppRoute[key]] as [string, any, AppRoute],
    )
    .find(([_, value]) => value === currentMatch.path)

  if (!match) {
    throw new Error(`Could not find route ${currentMatch.path} in route enum`)
  }
  const matchingRoute = match[2]
  return { route: matchingRoute, params: currentMatch.params }
}
