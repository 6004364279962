import React from "react"
import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import { useAsyncAction } from "state/async"
import { ChartDimensionDto } from "api/timelight-api"

const formatDims = (dimensions: ChartDimensionDto[]) => {
  const series = []
  const yAxis = []
  for (let i = 0; i < dimensions.length; i++) {
    series.push({
      name: dimensions[i].dimension,
      data: dimensions[i].data,
      yAxis: i,
    })

    yAxis.push({
      title: {
        text: dimensions[i].dimension,
      },
      height: 50,
      top: 50 * (i + 1),
      offset: 0,
      index: i,
    })
  }

  return { yAxis, series }
}

export interface ChartProps {
  title: string
  getData: () => Promise<ChartDimensionDto[]>
}

export const DataChart = (props: ChartProps) => {
  const dimensions = props.getData()

  const [{ data }] = useAsyncAction(async () => formatDims(await dimensions), {
    shouldTrigger: true,
  })

  if (data) {
    const chartHeight = data.series.length * 55 + 20 + "px"

    const options = {
      title: {
        text: props.title,
      },

      series: data.series,
      yAxis: data.yAxis,
    }
    return (
      <HighchartsReact containerProps={{ style: { height: chartHeight } }} highcharts={Highcharts} options={options} />
    )
  }
  return <></>
}
