import React from "react"
import { useApiClient } from "../state/api"
import {
  extractColumnConfigAndExportConfig,
  createTextColumnConfig,
  createTypeORMCrudLimit,
  createTypeORMCrudSort,
  createNumericColumnConfig,
} from "./MaterialTable/material-table-helpers"
import { MaterialTable } from "./MaterialTable"
import * as lodash from "lodash"
import { AnyChildren } from "../react-type-helpers"
import { useAsyncAction } from "../state/async"
import Loader from "./Loader"
import { createTypeORMCrudPage, createDateColumnConfig } from "./MaterialTable/material-table-helpers"
import { Individu } from "api/timelight-api"

export const IndividuSelectTable = React.memo(function IndividuSelectTableComponent({
  initialOnlySelection = false,
  sourceId,
  selectedIndividuIds,
  onChange,
  title,
  pageSize = 5,
  readonly = false,
}: {
  initialOnlySelection?: boolean
  sourceId: number
  selectedIndividuIds: number[]
  onChange: (individus: Individu[]) => void
  title: AnyChildren
  pageSize?: number
  readonly?: boolean
}) {
  const [selection, setSelection] = React.useState<Individu[] | null>(null)
  const api = useApiClient()

  // fetch initial selection
  const [{ data: initialValues }] = useAsyncAction(
    async () => {
      const individus = await api.getManyBaseIndividuControllerIndividu({
        filter: [`id||$in||${selectedIndividuIds.concat([-1]).join(",")}`],
      })
      return individus.data
    },
    {
      shouldTrigger: true,
      throwErrors: true,
      selectedIndividuIds,
    },
  )
  if (selection === null && initialValues !== null) {
    setSelection(initialValues)
  }

  function onSelectionChange(individus: Individu[]) {
    setSelection(individus)
    onChange(individus)
  }

  const { columns } = extractColumnConfigAndExportConfig<Individu>([
    createNumericColumnConfig({
      title: "Id",
      getValue: (row) => row.id,
      field: "id",
    }),
    createTextColumnConfig({
      title: "Classe",
      getTextValue: (row) => row.individuClass,
      field: "individuClass",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
  ])

  return (
    <div style={{ position: "relative" }}>
      {!selection && <Loader />}
      {selection && (
        <MaterialTable
          title={typeof title === "string" ? title : <>{title}</>}
          onRowClick={(row) => {
            if (row && !readonly) {
              onSelectionChange(
                selection !== null && selection.some((s) => s.id === row.id)
                  ? selection.filter((s) => s.id !== row.id)
                  : lodash.uniq([...(selection || []), row]),
              )
            }
          }}
          data={async (query) => {
            const res = await api.getManyBaseIndividuControllerIndividu({
              filter: [`sourceId||$in||${sourceId}`],
              limit: createTypeORMCrudLimit(query),
              page: createTypeORMCrudPage(query),
              sort: createTypeORMCrudSort(query),
            })

            return {
              page: query.page,
              totalCount: res.total,
              data: res.data,
            }
          }}
          selectedRows={selection || []}
          onSelectionChange={onSelectionChange}
          columns={columns}
          options={{ pageSize, selection: !readonly, filtering: false, exportButton: false, search: false }}
        />
      )}
    </div>
  )
})
