/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PredictionIndividu
 */
export interface PredictionIndividu {
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof PredictionIndividu
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof PredictionIndividu
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  individuId: number
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  companyId: number
  /**
   *
   * @type {number}
   * @memberof PredictionIndividu
   */
  predictionTaskId: number
  /**
   *
   * @type {string}
   * @memberof PredictionIndividu
   */
  classe: string | null
}

export function PredictionIndividuFromJSON(json: any): PredictionIndividu {
  return PredictionIndividuFromJSONTyped(json, false)
}

export function PredictionIndividuFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionIndividu {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    individuId: json["individuId"],
    companyId: json["companyId"],
    predictionTaskId: json["predictionTaskId"],
    classe: json["classe"],
  }
}

export function PredictionIndividuToJSON(value?: PredictionIndividu | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    individuId: value.individuId,
    companyId: value.companyId,
    predictionTaskId: value.predictionTaskId,
    classe: value.classe,
  }
}
