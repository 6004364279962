import React from "react"
import { Grid, InputLabel } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import PageContainer from "component/PageContainer"
import PageBlock from "component/PageBlock"
import AppHeader from "../../component/AppHeader"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useAppRoute } from "state/route"
import { useApiClient, useSpectralSource } from "state/api"
import { formatDate } from "lib/date"
import { createIndividuSelectTableField, IndividuSelectTableFormState } from "component/form/IndividuSelectTableField"
import { Form } from "react-final-form"
import { DataChart } from "./DataChart"
import { createListSelectField } from "component/form/ListSelectField"
import { useAsyncAction } from "state/async"
import Loader from "component/Loader"

interface FrequencyBand {
  low_frequency: number
  high_frequency: number
}

interface FormState {
  individuIds: IndividuSelectTableFormState
  frequencyBand: FrequencyBand
}

const frequencyBands = [
  { label: "delta (0.5 - 4)", value: { low_frequency: 0.5, high_frequency: 4 } },
  { label: "theta (4 - 8)", value: { low_frequency: 4, high_frequency: 8 } },
  { label: "alpha (8 - 12)", value: { low_frequency: 8, high_frequency: 12 } },
  { label: "beta  (12 - 15)", value: { low_frequency: 12, high_frequency: 15 } },
  { label: "gamma  (25 - 30)", value: { low_frequency: 25, high_frequency: 30 } },
]

export default function SpectralSourceView() {
  const api = useApiClient()
  const currentRoute = useAppRoute()
  const spectralSourceId = parseInt(currentRoute.params.sourceId || "-1", 10)

  // fetch page data
  const [{ data: source }] = useSpectralSource({
    shouldTrigger: spectralSourceId > 0,
    spectralSourceId,
  })

  const [{ data: initialValues }] = useAsyncAction(
    async () => {
      const individus = await api.getManyBaseIndividuControllerIndividu({
        filter: [`sourceId||$in||${spectralSourceId}`],
      })
      const selectedIndividuIds = individus.data.map((i) => i.id)

      const values: FormState = {
        individuIds: { sourceId: spectralSourceId, selectedIndividuIds },
        frequencyBand: frequencyBands[0].value,
      }

      return values
    },
    {
      shouldTrigger: true,
      throwErrors: true,
    },
  )

  const getData = (values: FormState) => {
    return () => {
      const dimensions = api.spectralSourceControllerGetChartData({
        spectralSourceChartInput: { individuIds: values.individuIds.selectedIndividuIds },
      })
      return dimensions
    }
  }

  const getBandData = (values: FormState) => {
    return () => {
      const dimensions = api.spectralSourceControllerGetChartDataBand({
        spectralSourceChartBandInput: {
          individuIds: values.individuIds.selectedIndividuIds,
          low_frequency: values.frequencyBand.low_frequency,
          high_frequency: values.frequencyBand.high_frequency,
        },
      })

      return dimensions
    }
  }

  return (
    <PageContainer title={"source details"}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Détails de la source
            {source && <>: {source.name}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!source} title={"Détails de la source: "}>
          {source && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <dl>
                <dt>Nom de la source :</dt>
                <dd>
                  <strong>{source.name}</strong>
                </dd>
                <dt>Date de création :</dt>
                <dd>
                  <strong>{formatDate(source.createdDate)}</strong>
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>
        {!initialValues ? (
          <Loader />
        ) : (
          <Form<FormState>
            initialValues={initialValues}
            validate={(values) => {
              const errors: { [key in keyof typeof values]?: string[] } = {
                individuIds: [],
              }

              if (values.individuIds.selectedIndividuIds.length < 1) {
                errors.individuIds?.push("Veuillez sélectionner au moins 1 individu")
              }

              return errors
            }}
            onSubmit={async (values, form) => {
              console.log(values)
            }}
            render={({ handleSubmit, dirty, submitting, pristine, values, valid, submitSucceeded }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <PageBlock title="Selection des données">
                    {createIndividuSelectTableField<FormState>({
                      name: "individuIds",
                      title: "Individus",
                      pageSize: 10,
                    })}
                  </PageBlock>
                  <PageBlock>
                    <DataChart
                      title="Data Chart"
                      getData={getData(values)}
                      key={values.individuIds.selectedIndividuIds.length}
                    />
                  </PageBlock>
                  <PageBlock>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <InputLabel style={{ marginRight: "1em", width: "150px" }}>Bande de frequence : </InputLabel>
                      {createListSelectField<FormState, { label: string; value: FrequencyBand }>({
                        name: "frequencyBand",
                        items: frequencyBands,
                      })}
                    </div>
                    <DataChart
                      title="Frequency Chart"
                      getData={getBandData(values)}
                      key={values.frequencyBand.low_frequency && values.frequencyBand.high_frequency}
                    />
                  </PageBlock>
                </form>
              )
            }}
          />
        )}{" "}
      </PageContent>
    </PageContainer>
  )
}
