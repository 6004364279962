import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import AppHeader from "../../component/AppHeader"
import { createTableExportCsv, extractColumnConfigAndExportConfig, MaterialTable } from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useApiClient } from "../../state/api"
import {
  createTextColumnConfig,
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
} from "../../component/MaterialTable/material-table-helpers"
import { AppRoute } from "state/route"
import { buildUrl } from "../../component/AppLink"
import { useHistory } from "react-router"
import {
  createTypeORMCrudSort,
  createDateColumnConfig,
  Query,
} from "../../component/MaterialTable/material-table-helpers"
import { QueryResult } from "material-table"
import { ModelTask } from "api/timelight-api"
import AddIcon from "@material-ui/icons/Add"
import { AppButton } from "component/AppButton"
import AppLink from "../../component/AppLink/index"
import { createTaskStatusColumnConfig } from "../../component/TaskStatusActivity"

// history.push(buildUrl({ route: AppRoute.FORECAST_TASK_VIEW, params: { taskId: row.id + "" } }))
export default function ModelTaskList() {
  return (
    <PageContainer title="Vos modèles">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Gestion de vos modèles
          </Typography>
          <AppLink route={AppRoute.SPECTRAL_MODEL_TASK_CREATE}>
            <AppButton variant="outlined" style={{ color: "white", borderColor: "white" }}>
              <AddIcon /> Nouveau modèle
            </AppButton>
          </AppLink>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <ModelTaskTable />
      </PageContent>
    </PageContainer>
  )
}

const ModelTaskTable = React.memo(function ModelTaskTableComponent() {
  const history = useHistory()
  const api = useApiClient()
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<ModelTask>([
    createTextColumnConfig({
      title: "Modèle",
      getTextValue: (row) => row.title,
      field: "title",
    }),
    createTextColumnConfig({
      title: "FeatureExtractionMethod",
      getTextValue: (row) => row.featureExtractionMethod,
      field: "featureExtractionMethod",
    }),
    createTextColumnConfig({
      title: "MachineLearningMethod",
      getTextValue: (row) => row.machineLearningMethod,
      field: "machineLearningMethod",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
    createTaskStatusColumnConfig<ModelTask>(),
  ])

  async function getData(query: Query<ModelTask>): Promise<QueryResult<ModelTask>> {
    const res = await api.getManyBaseModelTaskControllerModelTask({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      s: JSON.stringify({
        $and: [
          query.search && query.search.length > 0
            ? {
                $or: [{ tags: { $cont: query.search } }, { name: { $contL: query.search } }],
              }
            : undefined,
          query.filters && query.filters.length > 0
            ? {
                $and: query.filters
                  .filter((filter) => !!filter.column.field)
                  .map((filter) => ({
                    [filter.column.field as string]: { $contL: filter.value },
                  })),
              }
            : undefined,
        ].filter((f) => f),
      }),
    })

    return {
      page: query.page,
      totalCount: res.total,
      data: res.data,
    }
  }

  return (
    <>
      <PageBlock titleInContent={true}>
        <MaterialTable
          title={"Vos modèles"}
          data={getData}
          onRowClick={(row) =>
            row && history.push(buildUrl({ route: AppRoute.SPECTRAL_MODEL_TASK_VIEW, params: { taskId: row.id + "" } }))
          }
          columns={columns}
          options={{
            exportCsv: createTableExportCsv({
              exportConfig,
              fileName: `sources.csv`,
              getData,
            }),
          }}
        />
      </PageBlock>
    </>
  )
})
