/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManyIndividuResponseDto,
  GetManyIndividuResponseDtoFromJSON,
  GetManyIndividuResponseDtoToJSON,
  Individu,
  IndividuFromJSON,
  IndividuToJSON,
  IndividuInputDto,
  IndividuInputDtoFromJSON,
  IndividuInputDtoToJSON,
} from "../models"

export interface GetManyBaseIndividuControllerIndividuRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseIndividuControllerIndividuRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface IndividuControllerCreateIndividuRequest {
  individuInputDto: IndividuInputDto
}

/**
 * no description
 */
export class IndividuApi extends runtime.BaseAPI {
  /**
   * Retrieve many Individu
   */
  async getManyBaseIndividuControllerIndividuRaw(
    requestParameters: GetManyBaseIndividuControllerIndividuRequest,
  ): Promise<runtime.ApiResponse<GetManyIndividuResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/individu`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyIndividuResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many Individu
   */
  async getManyBaseIndividuControllerIndividu(
    requestParameters: GetManyBaseIndividuControllerIndividuRequest,
  ): Promise<GetManyIndividuResponseDto> {
    const response = await this.getManyBaseIndividuControllerIndividuRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one Individu
   */
  async getOneBaseIndividuControllerIndividuRaw(
    requestParameters: GetOneBaseIndividuControllerIndividuRequest,
  ): Promise<runtime.ApiResponse<Individu>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseIndividuControllerIndividu.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/individu/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => IndividuFromJSON(jsonValue))
  }

  /**
   * Retrieve one Individu
   */
  async getOneBaseIndividuControllerIndividu(
    requestParameters: GetOneBaseIndividuControllerIndividuRequest,
  ): Promise<Individu> {
    const response = await this.getOneBaseIndividuControllerIndividuRaw(requestParameters)
    return await response.value()
  }

  /**
   */
  async individuControllerCreateIndividuRaw(
    requestParameters: IndividuControllerCreateIndividuRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.individuInputDto === null || requestParameters.individuInputDto === undefined) {
      throw new runtime.RequiredError(
        "individuInputDto",
        "Required parameter requestParameters.individuInputDto was null or undefined when calling individuControllerCreateIndividu.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/individu`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IndividuInputDtoToJSON(requestParameters.individuInputDto),
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   */
  async individuControllerCreateIndividu(requestParameters: IndividuControllerCreateIndividuRequest): Promise<void> {
    await this.individuControllerCreateIndividuRaw(requestParameters)
  }
}
