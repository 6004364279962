/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MachineLearningMethod {
  SVM = "SVM",
  RandomForest = "Random Forest",
  DecisionTrees = "Decision Trees",
}

export function MachineLearningMethodFromJSON(json: any): MachineLearningMethod {
  return MachineLearningMethodFromJSONTyped(json, false)
}

export function MachineLearningMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineLearningMethod {
  return json as MachineLearningMethod
}

export function MachineLearningMethodToJSON(value?: MachineLearningMethod | null): any {
  return value as any
}
