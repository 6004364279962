/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { TaskStatus, TaskStatusFromJSON, TaskStatusFromJSONTyped, TaskStatusToJSON } from "./"

/**
 *
 * @export
 * @interface PredictionTask
 */
export interface PredictionTask {
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof PredictionTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof PredictionTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof PredictionTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof PredictionTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof PredictionTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof PredictionTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof PredictionTask
   */
  error: string | null
  /**
   *
   * @type {string}
   * @memberof PredictionTask
   */
  title: string
  /**
   *
   * @type {number}
   * @memberof PredictionTask
   */
  modelId: number
}

export function PredictionTaskFromJSON(json: any): PredictionTask {
  return PredictionTaskFromJSONTyped(json, false)
}

export function PredictionTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    title: json["title"],
    modelId: json["modelId"],
  }
}

export function PredictionTaskToJSON(value?: PredictionTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    title: value.title,
    modelId: value.modelId,
  }
}
