/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SpectralSourceChartBandInput
 */
export interface SpectralSourceChartBandInput {
  /**
   *
   * @type {Array<number>}
   * @memberof SpectralSourceChartBandInput
   */
  individuIds: Array<number>
  /**
   *
   * @type {number}
   * @memberof SpectralSourceChartBandInput
   */
  low_frequency: number
  /**
   *
   * @type {number}
   * @memberof SpectralSourceChartBandInput
   */
  high_frequency: number
}

export function SpectralSourceChartBandInputFromJSON(json: any): SpectralSourceChartBandInput {
  return SpectralSourceChartBandInputFromJSONTyped(json, false)
}

export function SpectralSourceChartBandInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpectralSourceChartBandInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    individuIds: json["individuIds"],
    low_frequency: json["low_frequency"],
    high_frequency: json["high_frequency"],
  }
}

export function SpectralSourceChartBandInputToJSON(value?: SpectralSourceChartBandInput | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    individuIds: value.individuIds,
    low_frequency: value.low_frequency,
    high_frequency: value.high_frequency,
  }
}
