/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SpectralSourceDimension
 */
export interface SpectralSourceDimension {
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimension
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SpectralSourceDimension
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SpectralSourceDimension
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimension
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimension
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimension
   */
  individuId: number
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimension
   */
  companyId: number
  /**
   *
   * @type {string}
   * @memberof SpectralSourceDimension
   */
  dimension: string
  /**
   *
   * @type {Array<number>}
   * @memberof SpectralSourceDimension
   */
  data: Array<number>
}

export function SpectralSourceDimensionFromJSON(json: any): SpectralSourceDimension {
  return SpectralSourceDimensionFromJSONTyped(json, false)
}

export function SpectralSourceDimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpectralSourceDimension {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    individuId: json["individuId"],
    companyId: json["companyId"],
    dimension: json["dimension"],
    data: json["data"],
  }
}

export function SpectralSourceDimensionToJSON(value?: SpectralSourceDimension | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    individuId: value.individuId,
    companyId: value.companyId,
    dimension: value.dimension,
    data: value.data,
  }
}
