/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManyPredictionTaskResponseDto,
  GetManyPredictionTaskResponseDtoFromJSON,
  GetManyPredictionTaskResponseDtoToJSON,
  PredictionResult,
  PredictionResultFromJSON,
  PredictionResultToJSON,
  PredictionTask,
  PredictionTaskFromJSON,
  PredictionTaskToJSON,
  PredictionTaskInputs,
  PredictionTaskInputsFromJSON,
  PredictionTaskInputsToJSON,
} from "../models"

export interface GetManyBasePredictionTaskControllerPredictionTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBasePredictionTaskControllerPredictionTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface PredictionTaskControllerCreateOneRequest {
  predictionTaskInputs: PredictionTaskInputs
}

export interface PredictionTaskControllerDeleteOneRequest {
  predictionTaskId: number
}

export interface PredictionTaskControllerGetPredictionResultsRequest {
  predictionTaskId: number
}

export interface PredictionTaskControllerUpdateOneRequest {
  predictionTaskId: number
  predictionTaskInputs: PredictionTaskInputs
}

/**
 * no description
 */
export class PredictionApi extends runtime.BaseAPI {
  /**
   * Retrieve many PredictionTask
   */
  async getManyBasePredictionTaskControllerPredictionTaskRaw(
    requestParameters: GetManyBasePredictionTaskControllerPredictionTaskRequest,
  ): Promise<runtime.ApiResponse<GetManyPredictionTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyPredictionTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many PredictionTask
   */
  async getManyBasePredictionTaskControllerPredictionTask(
    requestParameters: GetManyBasePredictionTaskControllerPredictionTaskRequest,
  ): Promise<GetManyPredictionTaskResponseDto> {
    const response = await this.getManyBasePredictionTaskControllerPredictionTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one PredictionTask
   */
  async getOneBasePredictionTaskControllerPredictionTaskRaw(
    requestParameters: GetOneBasePredictionTaskControllerPredictionTaskRequest,
  ): Promise<runtime.ApiResponse<PredictionTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBasePredictionTaskControllerPredictionTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one PredictionTask
   */
  async getOneBasePredictionTaskControllerPredictionTask(
    requestParameters: GetOneBasePredictionTaskControllerPredictionTaskRequest,
  ): Promise<PredictionTask> {
    const response = await this.getOneBasePredictionTaskControllerPredictionTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create a new prediction task
   */
  async predictionTaskControllerCreateOneRaw(
    requestParameters: PredictionTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<PredictionTask>> {
    if (requestParameters.predictionTaskInputs === null || requestParameters.predictionTaskInputs === undefined) {
      throw new runtime.RequiredError(
        "predictionTaskInputs",
        "Required parameter requestParameters.predictionTaskInputs was null or undefined when calling predictionTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PredictionTaskInputsToJSON(requestParameters.predictionTaskInputs),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskFromJSON(jsonValue))
  }

  /**
   * Create a new prediction task
   */
  async predictionTaskControllerCreateOne(
    requestParameters: PredictionTaskControllerCreateOneRequest,
  ): Promise<PredictionTask> {
    const response = await this.predictionTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete a prediction task
   */
  async predictionTaskControllerDeleteOneRaw(
    requestParameters: PredictionTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<PredictionTask>> {
    if (requestParameters.predictionTaskId === null || requestParameters.predictionTaskId === undefined) {
      throw new runtime.RequiredError(
        "predictionTaskId",
        "Required parameter requestParameters.predictionTaskId was null or undefined when calling predictionTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task/{predictionTaskId}`.replace(
        `{${"predictionTaskId"}}`,
        encodeURIComponent(String(requestParameters.predictionTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskFromJSON(jsonValue))
  }

  /**
   * Delete a prediction task
   */
  async predictionTaskControllerDeleteOne(
    requestParameters: PredictionTaskControllerDeleteOneRequest,
  ): Promise<PredictionTask> {
    const response = await this.predictionTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Get Results of a prediction task
   */
  async predictionTaskControllerGetPredictionResultsRaw(
    requestParameters: PredictionTaskControllerGetPredictionResultsRequest,
  ): Promise<runtime.ApiResponse<Array<PredictionResult>>> {
    if (requestParameters.predictionTaskId === null || requestParameters.predictionTaskId === undefined) {
      throw new runtime.RequiredError(
        "predictionTaskId",
        "Required parameter requestParameters.predictionTaskId was null or undefined when calling predictionTaskControllerGetPredictionResults.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task/result/{predictionTaskId}`.replace(
        `{${"predictionTaskId"}}`,
        encodeURIComponent(String(requestParameters.predictionTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PredictionResultFromJSON))
  }

  /**
   * Get Results of a prediction task
   */
  async predictionTaskControllerGetPredictionResults(
    requestParameters: PredictionTaskControllerGetPredictionResultsRequest,
  ): Promise<Array<PredictionResult>> {
    const response = await this.predictionTaskControllerGetPredictionResultsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a prediction task
   */
  async predictionTaskControllerUpdateOneRaw(
    requestParameters: PredictionTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<PredictionTask>> {
    if (requestParameters.predictionTaskId === null || requestParameters.predictionTaskId === undefined) {
      throw new runtime.RequiredError(
        "predictionTaskId",
        "Required parameter requestParameters.predictionTaskId was null or undefined when calling predictionTaskControllerUpdateOne.",
      )
    }

    if (requestParameters.predictionTaskInputs === null || requestParameters.predictionTaskInputs === undefined) {
      throw new runtime.RequiredError(
        "predictionTaskInputs",
        "Required parameter requestParameters.predictionTaskInputs was null or undefined when calling predictionTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/prediction-task/{predictionTaskId}`.replace(
        `{${"predictionTaskId"}}`,
        encodeURIComponent(String(requestParameters.predictionTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PredictionTaskInputsToJSON(requestParameters.predictionTaskInputs),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskFromJSON(jsonValue))
  }

  /**
   * Update a prediction task
   */
  async predictionTaskControllerUpdateOne(
    requestParameters: PredictionTaskControllerUpdateOneRequest,
  ): Promise<PredictionTask> {
    const response = await this.predictionTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }
}
