/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SpectralSourceChartInput
 */
export interface SpectralSourceChartInput {
  /**
   *
   * @type {Array<number>}
   * @memberof SpectralSourceChartInput
   */
  individuIds: Array<number>
}

export function SpectralSourceChartInputFromJSON(json: any): SpectralSourceChartInput {
  return SpectralSourceChartInputFromJSONTyped(json, false)
}

export function SpectralSourceChartInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpectralSourceChartInput {
  if (json === undefined || json === null) {
    return json
  }
  return {
    individuIds: json["individuIds"],
  }
}

export function SpectralSourceChartInputToJSON(value?: SpectralSourceChartInput | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    individuIds: value.individuIds,
  }
}
