/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import {
  GetManyModelTaskResponseDto,
  GetManyModelTaskResponseDtoFromJSON,
  GetManyModelTaskResponseDtoToJSON,
  ModelTask,
  ModelTaskFromJSON,
  ModelTaskToJSON,
  ModelTaskInputs,
  ModelTaskInputsFromJSON,
  ModelTaskInputsToJSON,
} from "../models"

export interface GetManyBaseModelTaskControllerModelTaskRequest {
  fields?: Array<string>
  s?: string
  filter?: Array<string>
  or?: Array<string>
  sort?: Array<string>
  join?: Array<string>
  limit?: number
  offset?: number
  page?: number
  cache?: number
}

export interface GetOneBaseModelTaskControllerModelTaskRequest {
  id: number
  fields?: Array<string>
  join?: Array<string>
  cache?: number
}

export interface ModelTaskControllerCreateOneRequest {
  modelTaskInputs: ModelTaskInputs
}

export interface ModelTaskControllerDeleteOneRequest {
  modelTaskId: number
}

export interface ModelTaskControllerUpdateOneRequest {
  modelTaskId: number
  modelTaskInputs: ModelTaskInputs
}

/**
 * no description
 */
export class ModelApi extends runtime.BaseAPI {
  /**
   * Retrieve many ModelTask
   */
  async getManyBaseModelTaskControllerModelTaskRaw(
    requestParameters: GetManyBaseModelTaskControllerModelTaskRequest,
  ): Promise<runtime.ApiResponse<GetManyModelTaskResponseDto>> {
    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.s !== undefined) {
      queryParameters["s"] = requestParameters.s
    }

    if (requestParameters.filter) {
      queryParameters["filter"] = requestParameters.filter
    }

    if (requestParameters.or) {
      queryParameters["or"] = requestParameters.or
    }

    if (requestParameters.sort) {
      queryParameters["sort"] = requestParameters.sort
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model-task`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => GetManyModelTaskResponseDtoFromJSON(jsonValue))
  }

  /**
   * Retrieve many ModelTask
   */
  async getManyBaseModelTaskControllerModelTask(
    requestParameters: GetManyBaseModelTaskControllerModelTaskRequest,
  ): Promise<GetManyModelTaskResponseDto> {
    const response = await this.getManyBaseModelTaskControllerModelTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Retrieve one ModelTask
   */
  async getOneBaseModelTaskControllerModelTaskRaw(
    requestParameters: GetOneBaseModelTaskControllerModelTaskRequest,
  ): Promise<runtime.ApiResponse<ModelTask>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getOneBaseModelTaskControllerModelTask.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    if (requestParameters.fields) {
      queryParameters["fields"] = requestParameters.fields.join(runtime.COLLECTION_FORMATS["csv"])
    }

    if (requestParameters.join) {
      queryParameters["join"] = requestParameters.join
    }

    if (requestParameters.cache !== undefined) {
      queryParameters["cache"] = requestParameters.cache
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model-task/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelTaskFromJSON(jsonValue))
  }

  /**
   * Retrieve one ModelTask
   */
  async getOneBaseModelTaskControllerModelTask(
    requestParameters: GetOneBaseModelTaskControllerModelTaskRequest,
  ): Promise<ModelTask> {
    const response = await this.getOneBaseModelTaskControllerModelTaskRaw(requestParameters)
    return await response.value()
  }

  /**
   * Create a new model task
   */
  async modelTaskControllerCreateOneRaw(
    requestParameters: ModelTaskControllerCreateOneRequest,
  ): Promise<runtime.ApiResponse<ModelTask>> {
    if (requestParameters.modelTaskInputs === null || requestParameters.modelTaskInputs === undefined) {
      throw new runtime.RequiredError(
        "modelTaskInputs",
        "Required parameter requestParameters.modelTaskInputs was null or undefined when calling modelTaskControllerCreateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model-task`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelTaskInputsToJSON(requestParameters.modelTaskInputs),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelTaskFromJSON(jsonValue))
  }

  /**
   * Create a new model task
   */
  async modelTaskControllerCreateOne(requestParameters: ModelTaskControllerCreateOneRequest): Promise<ModelTask> {
    const response = await this.modelTaskControllerCreateOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Delete a model task
   */
  async modelTaskControllerDeleteOneRaw(
    requestParameters: ModelTaskControllerDeleteOneRequest,
  ): Promise<runtime.ApiResponse<ModelTask>> {
    if (requestParameters.modelTaskId === null || requestParameters.modelTaskId === undefined) {
      throw new runtime.RequiredError(
        "modelTaskId",
        "Required parameter requestParameters.modelTaskId was null or undefined when calling modelTaskControllerDeleteOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model-task/{modelTaskId}`.replace(
        `{${"modelTaskId"}}`,
        encodeURIComponent(String(requestParameters.modelTaskId)),
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelTaskFromJSON(jsonValue))
  }

  /**
   * Delete a model task
   */
  async modelTaskControllerDeleteOne(requestParameters: ModelTaskControllerDeleteOneRequest): Promise<ModelTask> {
    const response = await this.modelTaskControllerDeleteOneRaw(requestParameters)
    return await response.value()
  }

  /**
   * Update a model task
   */
  async modelTaskControllerUpdateOneRaw(
    requestParameters: ModelTaskControllerUpdateOneRequest,
  ): Promise<runtime.ApiResponse<ModelTask>> {
    if (requestParameters.modelTaskId === null || requestParameters.modelTaskId === undefined) {
      throw new runtime.RequiredError(
        "modelTaskId",
        "Required parameter requestParameters.modelTaskId was null or undefined when calling modelTaskControllerUpdateOne.",
      )
    }

    if (requestParameters.modelTaskInputs === null || requestParameters.modelTaskInputs === undefined) {
      throw new runtime.RequiredError(
        "modelTaskInputs",
        "Required parameter requestParameters.modelTaskInputs was null or undefined when calling modelTaskControllerUpdateOne.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/model-task/{modelTaskId}`.replace(
        `{${"modelTaskId"}}`,
        encodeURIComponent(String(requestParameters.modelTaskId)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ModelTaskInputsToJSON(requestParameters.modelTaskInputs),
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelTaskFromJSON(jsonValue))
  }

  /**
   * Update a model task
   */
  async modelTaskControllerUpdateOne(requestParameters: ModelTaskControllerUpdateOneRequest): Promise<ModelTask> {
    const response = await this.modelTaskControllerUpdateOneRaw(requestParameters)
    return await response.value()
  }
}
