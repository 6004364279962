/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface Individu
 */
export interface Individu {
  /**
   *
   * @type {number}
   * @memberof Individu
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof Individu
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof Individu
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof Individu
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof Individu
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof Individu
   */
  sourceId: number
  /**
   *
   * @type {number}
   * @memberof Individu
   */
  companyId: number
  /**
   *
   * @type {string}
   * @memberof Individu
   */
  individuCode: string | null
  /**
   *
   * @type {string}
   * @memberof Individu
   */
  individuClass: string
}

export function IndividuFromJSON(json: any): Individu {
  return IndividuFromJSONTyped(json, false)
}

export function IndividuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Individu {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    sourceId: json["sourceId"],
    companyId: json["companyId"],
    individuCode: json["individuCode"],
    individuClass: json["individuClass"],
  }
}

export function IndividuToJSON(value?: Individu | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    companyId: value.companyId,
    individuCode: value.individuCode,
    individuClass: value.individuClass,
  }
}
