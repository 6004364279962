import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import AppHeader from "../../component/AppHeader"
import { createTableExportCsv, extractColumnConfigAndExportConfig, MaterialTable } from "../../component/MaterialTable"
import PageBlock from "../../component/PageBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { useApiClient } from "../../state/api"
import {
  createTextColumnConfig,
  createTypeORMCrudPage,
  createTypeORMCrudLimit,
  createNumericColumnConfig,
} from "../../component/MaterialTable/material-table-helpers"
import {
  createTypeORMCrudSort,
  createDateColumnConfig,
  Query,
} from "../../component/MaterialTable/material-table-helpers"
import { QueryResult } from "material-table"
import { SpectralSource } from "api/timelight-api"
import { AppRoute } from "state/route"
import { useHistory } from "react-router-dom"
import { buildUrl } from "component/AppLink"

export default function SpectralSourceList() {
  return (
    <PageContainer title="Vos sources">
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Gestion de vos sources
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <SpectraleSourceTable />
      </PageContent>
    </PageContainer>
  )
}

const SpectraleSourceTable = React.memo(function SpectralSourceTableComponent() {
  const api = useApiClient()
  const history = useHistory()
  const { columns, exportConfig } = extractColumnConfigAndExportConfig<SpectralSource>([
    createTextColumnConfig({
      title: "SpectralSource",
      getTextValue: (row) => row.name,
      field: "name",
    }),
    createNumericColumnConfig({
      title: "Fréquence",
      getValue: (row) => row.frequency,
      field: "frequency",
    }),
    createDateColumnConfig({
      title: "Date de création",
      getDate: (row) => row.createdDate,
      field: "createdDate",
    }),
  ])

  async function getData(query: Query<SpectralSource>): Promise<QueryResult<SpectralSource>> {
    const res = await api.getManyBaseSpectralSourceControllerSpectralSource({
      page: createTypeORMCrudPage(query),
      limit: createTypeORMCrudLimit(query),
      sort: createTypeORMCrudSort(query),
      s: JSON.stringify({
        $and: [
          query.search && query.search.length > 0
            ? {
                $or: [{ tags: { $cont: query.search } }, { name: { $contL: query.search } }],
              }
            : undefined,
          query.filters && query.filters.length > 0
            ? {
                $and: query.filters
                  .filter((filter) => !!filter.column.field)
                  .map((filter) => ({
                    [filter.column.field as string]: { $contL: filter.value },
                  })),
              }
            : undefined,
        ].filter((f) => f),
      }),
    })

    return {
      page: query.page,
      totalCount: res.total,
      data: res.data,
    }
  }

  return (
    <>
      <PageBlock titleInContent={true}>
        <MaterialTable
          title={"Vos sources spectrales"}
          data={getData}
          onRowClick={(row) =>
            row &&
            history.push(buildUrl({ route: AppRoute.SPECTRAL_DATA_SOURCE_VIEW, params: { sourceId: row.id + "" } }))
          }
          columns={columns}
          options={{
            exportCsv: createTableExportCsv({
              exportConfig,
              fileName: `sources.csv`,
              getData,
            }),
          }}
        />
      </PageBlock>
    </>
  )
})
