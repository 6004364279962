/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { PredictionTask, PredictionTaskFromJSON, PredictionTaskFromJSONTyped, PredictionTaskToJSON } from "./"

/**
 *
 * @export
 * @interface GetManyPredictionTaskResponseDto
 */
export interface GetManyPredictionTaskResponseDto {
  /**
   *
   * @type {Array<PredictionTask>}
   * @memberof GetManyPredictionTaskResponseDto
   */
  data: Array<PredictionTask>
  /**
   *
   * @type {number}
   * @memberof GetManyPredictionTaskResponseDto
   */
  count: number
  /**
   *
   * @type {number}
   * @memberof GetManyPredictionTaskResponseDto
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof GetManyPredictionTaskResponseDto
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof GetManyPredictionTaskResponseDto
   */
  pageCount: number
}

export function GetManyPredictionTaskResponseDtoFromJSON(json: any): GetManyPredictionTaskResponseDto {
  return GetManyPredictionTaskResponseDtoFromJSONTyped(json, false)
}

export function GetManyPredictionTaskResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetManyPredictionTaskResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json["data"] as Array<any>).map(PredictionTaskFromJSON),
    count: json["count"],
    total: json["total"],
    page: json["page"],
    pageCount: json["pageCount"],
  }
}

export function GetManyPredictionTaskResponseDtoToJSON(value?: GetManyPredictionTaskResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(PredictionTaskToJSON),
    count: value.count,
    total: value.total,
    page: value.page,
    pageCount: value.pageCount,
  }
}
