/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SpectralSourceDimensionInputDto
 */
export interface SpectralSourceDimensionInputDto {
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimensionInputDto
   */
  sourceId: number
  /**
   *
   * @type {number}
   * @memberof SpectralSourceDimensionInputDto
   */
  individuId: number
  /**
   *
   * @type {string}
   * @memberof SpectralSourceDimensionInputDto
   */
  dimension: string
  /**
   *
   * @type {string}
   * @memberof SpectralSourceDimensionInputDto
   */
  individuClass: string | null
  /**
   *
   * @type {Array<number>}
   * @memberof SpectralSourceDimensionInputDto
   */
  data?: Array<number> | null
}

export function SpectralSourceDimensionInputDtoFromJSON(json: any): SpectralSourceDimensionInputDto {
  return SpectralSourceDimensionInputDtoFromJSONTyped(json, false)
}

export function SpectralSourceDimensionInputDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SpectralSourceDimensionInputDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sourceId: json["sourceId"],
    individuId: json["individuId"],
    dimension: json["dimension"],
    individuClass: json["individuClass"],
    data: !exists(json, "data") ? undefined : json["data"],
  }
}

export function SpectralSourceDimensionInputDtoToJSON(value?: SpectralSourceDimensionInputDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sourceId: value.sourceId,
    individuId: value.individuId,
    dimension: value.dimension,
    individuClass: value.individuClass,
    data: value.data,
  }
}
