/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface SpectralSource
 */
export interface SpectralSource {
  /**
   *
   * @type {number}
   * @memberof SpectralSource
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof SpectralSource
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof SpectralSource
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof SpectralSource
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof SpectralSource
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof SpectralSource
   */
  companyId: number
  /**
   *
   * @type {string}
   * @memberof SpectralSource
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof SpectralSource
   */
  frequency: number
}

export function SpectralSourceFromJSON(json: any): SpectralSource {
  return SpectralSourceFromJSONTyped(json, false)
}

export function SpectralSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpectralSource {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    name: json["name"],
    frequency: json["frequency"],
  }
}

export function SpectralSourceToJSON(value?: SpectralSource | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    companyId: value.companyId,
    name: value.name,
    frequency: value.frequency,
  }
}
