import React from "react"
import PageBlock from "./PageBlock"
import { AppRoute, AppRouteParams } from "../state/route"
import { ButtonWithConfirmDialogProps, ButtonWithConfirmDialog } from "./ButtonWithConfirmDialog"

export function TaskActionsBlock({
  redirectToOnUpdate,
  deleteProps,
  updateProps,
}: {
  deleteProps: ButtonWithConfirmDialogProps
  updateProps: ButtonWithConfirmDialogProps
  redirectToOnUpdate?: { route: AppRoute; params?: AppRouteParams }
}) {
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <div style={{ maxWidth: updateProps ? "700px" : "500px" }}>
        <PageBlock alert={false} title="Actions">
          <div style={{ display: "flex" }}>
            <ButtonWithConfirmDialog {...deleteProps} />
            <div style={{ marginLeft: "2em" }}>
              <ButtonWithConfirmDialog {...updateProps} />
            </div>
          </div>
        </PageBlock>
      </div>
    </div>
  )
}
