import React from "react"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import PageBlock from "component/PageBlock"
import AppHeader from "../../component/AppHeader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import SideMenu from "../../component/SideMenu"
import { AppRoute, useAppRoute } from "../../state/route"
import { useApiClient, useModelTask } from "state/api"
import { isTaskEnded, useTaskStream } from "state/tasksStream"
import TaskStatusActivity from "component/TaskStatusActivity"
import { formatDate } from "lib/date"
import { TaskActionsBlock } from "component/TaskActionsBlock"

export default function ModelTaskView() {
  const currentRoute = useAppRoute()
  const modelTaskId = parseInt(currentRoute.params.taskId || "-1", 10)

  // fetch page data
  const [{ data: initModelTask }] = useModelTask({
    shouldTrigger: modelTaskId > 0,
    modelTaskId,
  })
  const task = useTaskStream(initModelTask)

  const api = useApiClient()

  return (
    <PageContainer title={"model details"}>
      <AppHeader>
        <Grid container direction="row" alignItems="center">
          <Typography variant="h6" style={{ display: "flex", alignItems: "center", fontSize: 15 }}>
            Détails du modèle
            {task && <>: {task.title}</>}
          </Typography>
        </Grid>
      </AppHeader>
      <SideMenu />
      <PageContent>
        <PageBlock loading={!task} title={"Détails du modèle: "}>
          {task && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <dl>
                <dt>Nom du modèle :</dt>
                <dd>
                  <strong>{task.title}</strong>
                </dd>

                <dt>Date d'entrainement :</dt>
                <dd>
                  <strong>{formatDate(task.createdDate)}</strong>
                </dd>

                <dt>Méthode d'extraction de features utilisé :</dt>
                <dd>
                  <strong>{task.featureExtractionMethod}</strong>
                </dd>

                <dt>Algorithme de Machine learning utilisé :</dt>
                <dd>
                  <strong>{task.machineLearningMethod}</strong>
                </dd>

                <dt>Status de la tâche</dt>
                <dd>
                  <TaskStatusActivity large={true} task={task} />
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>
        {task && task.error && (
          <PageBlock title="Erreur technique">
            <pre>{task.error}</pre>
          </PageBlock>
        )}
        <PageBlock loading={!task} title={"Performance du modèle: "}>
          {task && (
            <div>
              <table className="matrix">
                <tr>
                  <td style={{ all: "unset" }}></td>
                  <td>1</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{task.trueNegatives}</td>
                  <td>{task.falsePositives}</td>
                </tr>
                <tr>
                  <td>0</td>
                  <td>{task.falseNegatives}</td>
                  <td>{task.truePositives}</td>
                </tr>
              </table>
              <dl>
                <dt>f1 score :</dt>
                <dd>
                  <strong>{task.scoref1}</strong>
                </dd>
              </dl>
            </div>
          )}
        </PageBlock>
        {task && isTaskEnded(task) && (
          <TaskActionsBlock
            deleteProps={{
              buttonText: <>Supprimer le modèle</>,
              popinButtonText: <>Supprimer définitivement le modèle {task.title}</>,
              popinTitle: <>Supprimer le modèle {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce modèle</>,
              successMessage: `Modèle ${task.title} supprimé définitivement`,
              redirectToAfterAction: { route: AppRoute.SPECTRAL_MODEL_TASK_LIST },
              doAction: async () => {
                await api.modelTaskControllerDeleteOne({
                  modelTaskId: task.id,
                })
              },
            }}
            updateProps={{
              buttonText: <>Mettre à jour</>,
              popinButtonText: <>Supprimer définitivement les données du modèle {task.title}</>,
              popinTitle: <>La mise à jour supprimera les données du modèle {task.title} ?</>,
              popinWarning: <>Cette action est irréversible, vous perdrez toutes les données liées à ce modèle</>,
              redirectToAfterAction: { route: AppRoute.SPECTRAL_MODEL_TASK_UPDATE, params: { taskId: task.id + "" } },
              doAction: async () => {},
            }}
          />
        )}
      </PageContent>
    </PageContainer>
  )
}
