import { PredictionResult } from "./../api/timelight-api/models/PredictionResult"
import { PredictionIndividuApi } from "./../api/timelight-api/apis/PredictionIndividuApi"
import { IndividuApi } from "./../api/timelight-api/apis/IndividuApi"
import { PredictionTask } from "./../api/timelight-api/models/PredictionTask"
import { PredictionApi } from "./../api/timelight-api/apis/PredictionApi"
import { ModelTask } from "./../api/timelight-api/models/ModelTask"
import { AsyncHookParams, useAsyncAction } from "./async"
import { ModelApi } from "./../api/timelight-api/apis/ModelApi"
import { SpectralSourceDimensionApi } from "./../api/timelight-api/apis/SpectralSourceDimensionApi"
import { Configuration, SpectralSource, SpectralSourceApi } from "api/timelight-api"
import { useState } from "react"
import { UserApi } from "api/timelight-api"
import { useConfig } from "../config"
import { getInstanceMethodNames } from "../lib/array"
import { useAlertOnReadOnly, useAuth, useLogoutOnError } from "./auth"
import { BaseAPI } from "api/timelight-api"
import { deepFreeze } from "lib/deep-freeze"
import { ImportDataApi } from "../api/timelight-api/apis/ImportDataApi"

export type TimelightApi = Omit<
  UserApi &
    SpectralSourceApi &
    SpectralSourceDimensionApi &
    ModelApi &
    PredictionApi &
    IndividuApi &
    PredictionIndividuApi &
    ImportDataApi,
  "configuration" | "basePath" | "fetch"
>

export function useApiClient(): TimelightApi {
  const { jwt } = useAuth()
  const { API_URL } = useConfig()

  const params: Configuration = new Configuration({
    basePath: API_URL,
    accessToken: jwt || undefined,
  })

  const [api] = useState<any>(() =>
    [
      new UserApi(params),
      new SpectralSourceApi(params),
      new SpectralSourceDimensionApi(params),
      new ModelApi(params),
      new PredictionApi(params),
      new IndividuApi(params),
      new PredictionIndividuApi(params),
      new ImportDataApi(params),
    ]
      .map((apiPart) =>
        getInstanceMethodNames(apiPart, BaseAPI.prototype).reduce(
          (agg, methodName) => Object.assign(agg, { [methodName]: (apiPart as any)[methodName].bind(apiPart) }),
          {},
        ),
      )
      .reduce((agg, apiPart) => Object.assign(agg, apiPart), {}),
  )

  return api
}

export function useSpectralSource(initialParams: AsyncHookParams<{ spectralSourceId: number }>) {
  const api = useApiClient()
  const res = useAsyncAction<SpectralSource, AsyncHookParams<{ spectralSourceId: number }>>(
    async ({ spectralSourceId }) =>
      deepFreeze(await api.getOneBaseSpectralSourceControllerSpectralSource({ id: spectralSourceId })),
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function useModelTask(initialParams: AsyncHookParams<{ modelTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<ModelTask, AsyncHookParams<{ modelTaskId: number }>>(async ({ modelTaskId }) => {
    return deepFreeze(
      await api.getOneBaseModelTaskControllerModelTask({
        id: modelTaskId,
      }),
    )
  }, initialParams)
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function usePredictionTask(initialParams: AsyncHookParams<{ predictionTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<PredictionTask, AsyncHookParams<{ predictionTaskId: number }>>(
    async ({ predictionTaskId }) => {
      return deepFreeze(
        await api.getOneBasePredictionTaskControllerPredictionTask({
          id: predictionTaskId,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function usePredictionResult(initialParams: AsyncHookParams<{ predictionTaskId: number }>) {
  const api = useApiClient()

  const res = useAsyncAction<PredictionResult[], AsyncHookParams<{ predictionTaskId: number }>>(
    async ({ predictionTaskId }) => {
      return deepFreeze(
        await api.predictionTaskControllerGetPredictionResults({
          predictionTaskId,
        }),
      )
    },
    initialParams,
  )
  useAlertOnReadOnly(res)
  useLogoutOnError(res)
  return res
}

export function uploadFile(file: File, options: { jwt: string | null; url: string }) {
  const headers: { [header: string]: string } = {
    "Content-Type": file.type,
  }
  if (options.jwt) {
    headers.Authorization = `Bearer ${options.jwt}`
  }

  return fetch(options.url, {
    method: "POST",
    headers,
    body: file,
  }).then((res) => res.json())
}

export function extractConstraints(res: any): { [key: string]: string } | null {
  if (res.constraints) {
    return res.constraints
  }
  let constraints = {}
  if (res.children && Array.isArray(res.children)) {
    for (const ch of res.children) {
      const constr = extractConstraints(ch)
      if (constr) {
        constraints = { ...constraints, ...constr }
      }
    }
  }
  if (res.message && Array.isArray(res.message)) {
    for (const ch of res.message) {
      const constr = extractConstraints(ch)
      if (constr) {
        constraints = { ...constraints, ...constr }
      }
    }
  }
  if (Object.keys(constraints).length > 0) {
    return constraints
  }
  return null
}
