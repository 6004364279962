import React from "react"
import AppSnackbar from "../component/AppSnackbar"
import { AnyChildren } from "../react-type-helpers"
import { useJwt } from "./auth"
import { useConfig } from "../config"
import io from "socket.io-client"
import { TaskStatus } from "api/timelight-api"
import { AppRoute } from "./route"
import { buildUrl } from "../component/AppLink"
import ViewIcon from "@material-ui/icons/NavigateNext"
import IconButton from "@material-ui/core/IconButton"

export type Task = {
  id: number
  title: string
  taskType: string
  status: TaskStatus
} // @todo

interface TasksStreamContextState {
  addTask: (t: Task) => void
  getTask: (id: number) => Task | null
  getLastStarted: () => Task | null
}

const defaultValue: TasksStreamContextState = {
  addTask: () => {},
  getTask: () => null,
  getLastStarted: () => null,
}
const TasksStreamContext = React.createContext<TasksStreamContextState>(defaultValue)

let socket: SocketIOClient.Socket | null = null
/*
const TASK_TYPE_SERIE_SYNC = "serie-sync"
const TASK_TYPE_SOURCE_CLUSTER = "source-cluster"
const TASK_TYPE_FORECAST = "forecast"
const TASK_TYPE_CONTEXT_IMPACT = "context-impact"
*/
export function buildTaskUrl(task: Task) {
  const route =
    /*
    task.taskType === TASK_TYPE_SERIE_SYNC
      ? AppRoute.SERIE_SYNC_TASK_VIEW
      : task.taskType === TASK_TYPE_SOURCE_CLUSTER
      ? AppRoute.SOURCE_CLUSTER_TASK_VIEW
      : task.taskType === TASK_TYPE_FORECAST
      ? AppRoute.FORECAST_TASK_VIEW
      : task.taskType === TASK_TYPE_CONTEXT_IMPACT
      ? AppRoute.CONTEXT_IMPACT_TASK_VIEW
      : */ AppRoute.HOME
  return buildUrl({
    route,
    params: { taskId: task.id + "" },
  })
}

export function isTaskEnded<TaskType extends Task>(t: TaskType) {
  return t.status === TaskStatus.Killed || t.status === TaskStatus.Failed || t.status === TaskStatus.Completed
}

// remove optional condition "-?"
// remove null possible values
// used to make TS understand that output values will be set
// when the task is successful
type NotEmptyValues<T> = {
  [P in keyof T]-?: Exclude<T[P], null | undefined>
}

export function isTaskSuccessfullyEnded<TaskType extends Task>(t: TaskType): t is NotEmptyValues<TaskType> {
  return t.status === TaskStatus.Completed
}

export function TasksStreamContextContextProvider({ children }: { children: AnyChildren }) {
  const jwt = useJwt()
  const { API_URL } = useConfig()
  // const history = useHistory()
  const [state, setState] = React.useState<{
    tasksById: { [id: number]: Task }
    lastUpdate: {
      message: string
      variant: "error" | "success" | "warning"
      url: string
    } | null
    lastStartedId: number | null
  }>({ tasksById: {}, lastUpdate: null, lastStartedId: null })

  React.useEffect(() => {
    if (!jwt) {
      return
    }
    if (!socket) {
      socket = io(API_URL, {
        transports: ["websocket"],
        query: { jwt },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
      })

      socket.emit("register-task-event")
    }

    function onEvent(t: Task) {
      try {
        // format task according to type
        /*if (t.taskType === TASK_TYPE_FORECAST) {
          t = ForecastTaskFromJSON(t)
        } else if (t.taskType === TASK_TYPE_SOURCE_CLUSTER) {
          t = SourceClusterTaskFromJSON(t)
        } else if (t.taskType === TASK_TYPE_SERIE_SYNC) {
          t = SerieSyncTaskFromJSON(t)
        } else if (t.taskType === TASK_TYPE_CONTEXT_IMPACT) {
          t = ContextImpactTaskFromJSON(t)
        }*/
        let newState = { ...state }
        newState = { ...newState, tasksById: { ...newState.tasksById, [t.id]: t } }

        if (t.status === TaskStatus.Waiting) {
          newState = { ...newState, lastStartedId: t.id }
        }

        if (t.status === TaskStatus.Completed) {
          newState = {
            ...newState,
            lastUpdate: { message: `Tâche ${t.title} terminée`, variant: "success", url: buildTaskUrl(t) },
          }
        } else if (t.status === TaskStatus.Failed || t.status === TaskStatus.Killed) {
          newState = {
            ...newState,
            lastUpdate: {
              message: `Tâche ${t.title} échouée`,
              variant: "error",
              url: buildTaskUrl(t),
            },
          }
        } else if (t.status === TaskStatus.Waiting) {
          newState = {
            ...newState,
            lastUpdate: {
              message: `Tâche ${t.title} en attente de traitement`,
              variant: "warning",
              url: buildTaskUrl(t),
            },
          }
        } else if (t.status === TaskStatus.Active) {
          newState = {
            ...newState,
            lastUpdate: {
              message: `Tâche ${t.title} en cours de traitement`,
              variant: "success",
              url: buildTaskUrl(t),
            },
          }
        }
        setState(newState)
      } catch (e) {
        // errors here should not crash the app
        // and should not prevent future events from being handled
        console.error(e)
      }
    }

    socket.on("register-task-event", onEvent)
    return () => {
      socket?.removeEventListener("register-task-event", onEvent)
    }
  }, [jwt, API_URL, state, setState])

  // and the provider provode... provided... provide... providera...
  // and the message is properly set
  return (
    <TasksStreamContext.Provider
      value={{
        addTask: (t: Task) => {
          // in case we reload the task page
          let newState = { ...state }
          let shouldUpdate = false
          if (!state.tasksById[t.id]) {
            newState = { ...newState, tasksById: { ...newState.tasksById, [t.id]: t } }
            shouldUpdate = true
          }

          // in case we reload the task page, this is the new "following up" task
          if (!state.lastStartedId && !isTaskEnded(t)) {
            newState = { ...newState, lastStartedId: t.id }
          }

          if (shouldUpdate) {
            setState(newState)
          }
        },
        getTask: (id) => {
          return state.tasksById[id] || null
        },
        getLastStarted: () => (state.lastStartedId && state.tasksById[state.lastStartedId]) || null,
      }}
    >
      <>
        <AppSnackbar
          message={
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ marginRight: "1em" }}>{state.lastUpdate?.message || ""}</div>
              <IconButton
                color="inherit"
                size="small"
                style={{ padding: 0 }}
                onClick={() => {
                  if (state.lastUpdate) {
                    window.location.assign(state.lastUpdate.url)
                  }
                }}
              >
                <ViewIcon />
              </IconButton>
            </div>
          }
          variant={state.lastUpdate?.variant || "info"}
          open={!!state.lastUpdate}
          onClose={() => setState({ ...state, lastUpdate: null })}
        />
        {children}
      </>
    </TasksStreamContext.Provider>
  )
}

export function useTaskStream<T extends Task>(task: T | null): T | null {
  const { getTask, addTask } = React.useContext(TasksStreamContext)

  // input task is not defined
  if (!task) {
    return null
  }

  // tell him to add this task if not known
  if (task) {
    addTask(task)
  }
  // get new version of task
  const t = (getTask(task.id) as unknown) as T

  // if no new version, return input, else, return new version
  if (!t) {
    return task
  } else {
    return t
  }
}

export function useLastStartedTask(): Task | null {
  const { getLastStarted } = React.useContext(TasksStreamContext)
  return getLastStarted()
}
