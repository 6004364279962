import { MachineLearningMethod } from "api/timelight-api"

export const machineLearningMethods: { label: string; value: MachineLearningMethod }[] = [
  { label: "SVM", value: MachineLearningMethod.SVM },
  { label: "Random Forest", value: MachineLearningMethod.RandomForest },
  { label: "Arbres de décision", value: MachineLearningMethod.DecisionTrees },
]

export const getMachineLearingMethodName = (methodeML: MachineLearningMethod) => {
  return machineLearningMethods.find((methode) => methode.value === methodeML)?.label
}
