/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PredictionResult
 */
export interface PredictionResult {
  /**
   *
   * @type {string}
   * @memberof PredictionResult
   */
  predictedClass: string
  /**
   *
   * @type {number}
   * @memberof PredictionResult
   */
  individuId: number
  /**
   *
   * @type {string}
   * @memberof PredictionResult
   */
  individuClass: string
  /**
   *
   * @type {string}
   * @memberof PredictionResult
   */
  individuCode: string
}

export function PredictionResultFromJSON(json: any): PredictionResult {
  return PredictionResultFromJSONTyped(json, false)
}

export function PredictionResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    predictedClass: json["predictedClass"],
    individuId: json["individuId"],
    individuClass: json["individuClass"],
    individuCode: json["individuCode"],
  }
}

export function PredictionResultToJSON(value?: PredictionResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    predictedClass: value.predictedClass,
    individuId: value.individuId,
    individuClass: value.individuClass,
    individuCode: value.individuCode,
  }
}
