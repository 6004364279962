import React from "react"
import { Field } from "react-final-form"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { FormHelperText } from "@material-ui/core"
import * as lodash from "lodash"
import ModelTaskSelect from "component/ModelTaskSelect"

export interface ModelTaskSelectFormState {
  modelTaskId: number
  modelTaskIdExclusions?: number[]
}

export function createModelTaskSelectField<FormState>(props: {
  name: keyof FormState & string
  onlyValid?: boolean
  required?: boolean
}) {
  return <Field {...props} render={ModelTaskSelectFieldRender} />
}

const ModelTaskSelectFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<ModelTaskSelectFormState, { onlyValid?: boolean }>) => {
    return (
      <div>
        <ModelTaskSelect
          modelTaskId={props.input.value.modelTaskId}
          setModelTaskId={(id) => props.input.onChange({ ...props.input.value, modelTaskId: id })}
          modelTaskIdExclusions={props.input.value.modelTaskIdExclusions}
          isClearable={true}
          onlyValid={props.onlyValid}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<ModelTaskSelectFormState, {}>) => {
    return (
      "" +
      props.input.value.modelTaskId +
      "" +
      props.input.value.modelTaskIdExclusions?.sort().join("-") +
      getFormFieldPropsMemoizeKey(props)
    )
  },
)
