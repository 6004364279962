import { makeStyles } from "@material-ui/core/styles"
import PageBlock from "component/PageBlock"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import AppHeader from "../../component/AppHeader"
import Loader from "../../component/Loader"
import LoaderBlock from "../../component/LoaderBlock"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useApiClient } from "../../state/api"
import { useApiExceptionHandler } from "../../state/auth"
import SideMenu from "component/SideMenu"
import { buildUrl } from "../../component/AppLink"
import { useHistory } from "react-router"
import { AppButton } from "../../component/AppButton"
import { useOnboardingState } from "../../state/onboarding"

const useStyles = makeStyles({
  text: {
    fontSize: "1.2em",
  },
})

export default function SpectralSourceCreateExample() {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const { t } = useTranslation()
  const api = useApiClient()
  const apiExceptionHandler = useApiExceptionHandler()
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Fichier importé avec succès",
  })
  const { sourceAdded } = useOnboardingState()

  async function importDemoData() {
    try {
      // create the source
      const dataCreate: any = await (await fetch("/data/res_EEG_schizophrenia.json")).json()
      console.log(dataCreate)
      const source = (await api.importDataControllerCreateSpectralSource({
        sourceName: "Données de démo",
        requestBody: dataCreate,
      })) as any
      return source
    } catch (e) {
      apiExceptionHandler(e)
      throw e
    }
  }

  return (
    <PageContainer title={`${t("source.Name")} de démonstration`}>
      <AppHeader color={true} openable={true} />
      <SideMenu />
      <PageContent padding={true}>
        <div style={{ maxWidth: 900, margin: "auto" }}>
          <SuccessMessage />
          {loading ? (
            <LoaderBlock
              message="Import en cours"
              helpText={`Nous préparons des données de démonstration afin
            de vous faire découvrir au mieux les fonctionnalités de ${t("global.appTitle")}.
            Cette opération peut prendre jusqu'à 1 minute.`}
            />
          ) : (
            <PageBlock title={`Créer ${t("source.onename")} de démonstration`}>
              <div style={{ maxWidth: "600px", margin: "auto", marginBottom: "2em" }}>
                <p className={classes.text}>
                  Afin de débuter l'utilisation de la plateforme, vous pouvez installer notre {t("source.name")} de
                  démonstration.
                </p>
                <p className={classes.text}>
                  Une nouvelle source nommée "Données de démo" va s'ajouter aux sources en cours. Elle contient une
                  configuration complète incluant des données historiques.
                </p>
                <p className={classes.text}>Cette opération peut prendre jusqu'à 2 minute.</p>
              </div>
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <label htmlFor="raised-button-file">
                  <AppButton
                    style={{ marginLeft: "1em" }}
                    disabled={loading}
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={loading ? <Loader /> : undefined}
                    onClick={async () => {
                      setLoading(true)
                      try {
                        const source = await importDemoData()
                        showSuccessMessage()
                        sourceAdded()
                        setTimeout(() => {
                          history.push(
                            buildUrl({
                              route: AppRoute.SPECTRAL_DATA_SOURCE_VIEW,
                              params: { sourceId: source.id + "" },
                            }),
                          )
                        }, 1000)
                      } finally {
                        setLoading(false)
                      }
                    }}
                  >
                    Installer les données de démonstration
                  </AppButton>
                </label>
              </div>
            </PageBlock>
          )}
        </div>
      </PageContent>
    </PageContainer>
  )
}
