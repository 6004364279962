import { createBrowserHistory, UnregisterCallback } from "history"
import React from "react"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import { useConfig } from "../config"
import { createTrackPageView } from "../lib/tracking"
import Login from "../pages/auth/Login"
import Page404 from "../pages/Page404"
import Register from "../pages/auth/Register"
import { AppRoute } from "../state/route"
import { ErrorBoundary } from "./ErrorBoundary"
import Home from "../pages/Home"
import SpectralSourceList from "../pages/timelight-spectral-data/SpectralSourceList"
import { withAuthRedirects } from "../state/currentUser"
import { AuthError } from "../pages/auth/AuthError"
import ModelTaskList from "pages/timelight-spectral-data/ModelTaskList"
import ModelTaskView from "pages/timelight-spectral-data/ModelTaskView"
import { ModelTaskCreate } from "pages/timelight-spectral-data/ModelTaskCreate"
import PredictionTaskList from "pages/timelight-spectral-data/PredictionTaskList"
import { PredictionTaskCreate } from "pages/timelight-spectral-data/PredictionTaskCreate"
import PredictionTaskView from "pages/timelight-spectral-data/PredictionTaskView"
import SpectralSourceCreateHub from "pages/timelight-spectral-data/SpectralSourceCreateHub"
import SpectralSourceCreate from "pages/timelight-spectral-data/SpectralSourceCreate"
import SpectralSourceView from "pages/timelight-spectral-data/SpectralSourceView"
import SpectralSourceCreateExample from "../pages/timelight-spectral-data/SpectralSourceCreateExample"

const customHistory = createBrowserHistory()
let unListen: UnregisterCallback | null = null

export default function AppRouter() {
  const { SOCIAL_AUTH_ENABLED, USER_SIGN_UP_ENABLED, GOOGLE_ANALYTICS_UA } = useConfig()

  if (unListen) {
    unListen()
  }
  unListen = customHistory.listen(createTrackPageView(GOOGLE_ANALYTICS_UA))
  return (
    <ErrorBoundary>
      <Router history={customHistory}>
        <Switch>
          <Route path={AppRoute.SPECTRAL_DATA_SOURCE_LIST} exact component={withAuthRedirects(SpectralSourceList)} />
          <Route path={AppRoute.SPECTRAL_MODEL_TASK_LIST} exact component={withAuthRedirects(ModelTaskList)} />
          <Route path={AppRoute.SPECTRAL_MODEL_TASK_CREATE} exact component={withAuthRedirects(ModelTaskCreate)} />
          <Route path={AppRoute.SPECTRAL_MODEL_TASK_UPDATE} exact component={withAuthRedirects(ModelTaskCreate)} />
          <Route
            path={AppRoute.SPECTRAL_DATA_SOURCE_CREATE_HUB}
            exact
            component={withAuthRedirects(SpectralSourceCreateHub)}
          />
          <Route
            path={AppRoute.SPECTRAL_DATA_SOURCE_CREATE}
            exact
            component={withAuthRedirects(SpectralSourceCreate)}
          />
          <Route
            path={AppRoute.SPECTRAL_DATA_SOURCE_CREATE_EXAMPLE}
            exact
            component={withAuthRedirects(SpectralSourceCreateExample)}
          />

          <Route path={AppRoute.SPECTRAL_MODEL_TASK_VIEW} exact component={withAuthRedirects(ModelTaskView)} />
          <Route
            path={AppRoute.SPECTRAL_PREDICTION_TASK_LIST}
            exact
            component={withAuthRedirects(PredictionTaskList)}
          />
          <Route path={AppRoute.SPECTRAL_DATA_SOURCE_VIEW} exact component={withAuthRedirects(SpectralSourceView)} />
          <Route
            path={AppRoute.SPECTRAL_PREDICTION_TASK_CREATE}
            exact
            component={withAuthRedirects(PredictionTaskCreate)}
          />
          <Route
            path={AppRoute.SPECTRAL_PREDICTION_TASK_UPDATE}
            exact
            component={withAuthRedirects(PredictionTaskCreate)}
          />
          <Route
            path={AppRoute.SPECTRAL_PREDICTION_TASK_VIEW}
            exact
            component={withAuthRedirects(PredictionTaskView)}
          />
          <Route path={AppRoute.EMPTY_INSTANCE} exact component={withAuthRedirects(SpectralSourceCreateHub)} />

          <Route path={AppRoute.LOGIN} exact component={withAuthRedirects(Login)} />
          {USER_SIGN_UP_ENABLED && <Route path={AppRoute.REGISTER} exact component={withAuthRedirects(Register)} />}
          {SOCIAL_AUTH_ENABLED && <Route path={AppRoute.AUTH_ERROR} exact component={withAuthRedirects(AuthError)} />}
          <Route path={AppRoute.HOME} exact component={withAuthRedirects(Home)}>
            {" "}
            <Redirect to={AppRoute.SPECTRAL_DATA_SOURCE_LIST} />{" "}
          </Route>
          <Route component={withAuthRedirects(Page404)} />
        </Switch>
      </Router>
    </ErrorBoundary>
  )
}
