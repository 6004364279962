import React from "react"
import { Field } from "react-final-form"
import { CustomFieldRenderProps, getFormFieldPropsMemoizeKey } from "./decorator"
import { FormHelperText } from "@material-ui/core"
import * as lodash from "lodash"
import SpectralSourceSelect from "component/SpectralSourceSelect"

export interface SpectralSourceSelectFormState {
  sourceId: number | null
  sourceIdExclusions?: number[]
}

export function createSpectralSourceSelectField<FormState>(props: {
  name: keyof FormState & string
  required?: boolean
}) {
  return <Field {...props} render={SpectralSourceSelectFieldRender} />
}

// we cannot memoize this renderer as the underlying component sets the source
// this should not have happened but hey we're here already
const SpectralSourceSelectFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<SpectralSourceSelectFormState, {}>) => {
    return (
      <div>
        <SpectralSourceSelect
          spectralSourceId={props.input.value.sourceId}
          setSpectralSourceId={(id) => props.input.onChange({ ...props.input.value, sourceId: id })}
          sourceIdExclusions={props.input.value.sourceIdExclusions}
          isClearable={true}
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<SpectralSourceSelectFormState, {}>) => {
    return (
      "" +
      props.input.value.sourceId +
      "" +
      props.input.value.sourceIdExclusions?.sort().join("-") +
      getFormFieldPropsMemoizeKey(props)
    )
  },
)
