/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import { ModelTask, ModelTaskFromJSON, ModelTaskFromJSONTyped, ModelTaskToJSON } from "./"

/**
 *
 * @export
 * @interface GetManyModelTaskResponseDto
 */
export interface GetManyModelTaskResponseDto {
  /**
   *
   * @type {Array<ModelTask>}
   * @memberof GetManyModelTaskResponseDto
   */
  data: Array<ModelTask>
  /**
   *
   * @type {number}
   * @memberof GetManyModelTaskResponseDto
   */
  count: number
  /**
   *
   * @type {number}
   * @memberof GetManyModelTaskResponseDto
   */
  total: number
  /**
   *
   * @type {number}
   * @memberof GetManyModelTaskResponseDto
   */
  page: number
  /**
   *
   * @type {number}
   * @memberof GetManyModelTaskResponseDto
   */
  pageCount: number
}

export function GetManyModelTaskResponseDtoFromJSON(json: any): GetManyModelTaskResponseDto {
  return GetManyModelTaskResponseDtoFromJSONTyped(json, false)
}

export function GetManyModelTaskResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetManyModelTaskResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: (json["data"] as Array<any>).map(ModelTaskFromJSON),
    count: json["count"],
    total: json["total"],
    page: json["page"],
    pageCount: json["pageCount"],
  }
}

export function GetManyModelTaskResponseDtoToJSON(value?: GetManyModelTaskResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: (value.data as Array<any>).map(ModelTaskToJSON),
    count: value.count,
    total: value.total,
    page: value.page,
    pageCount: value.pageCount,
  }
}
