import { Input } from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import PageBlock from "component/PageBlock"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { AppRoute } from "state/route"
import AppHeader from "../../component/AppHeader"
import Loader from "../../component/Loader"
import PageContainer from "../../component/PageContainer"
import PageContent from "../../component/PageContent"
import { useSuccessMessage } from "../../component/SuccessMessage"
import { useConfig } from "../../config"
import { extractConstraints, uploadFile } from "../../state/api"
import { useApiExceptionHandler, useAuth } from "../../state/auth"
import SideMenu from "component/SideMenu"
import { useHistory } from "react-router"
import { buildUrl } from "../../component/AppLink"
import { AppButton } from "../../component/AppButton"
import { useOnboardingState } from "../../state/onboarding"

const useStyles = makeStyles((theme) => ({
  outerBox: { maxWidth: "70%", margin: "auto", padding: theme.spacing(4) },
  introText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(40),
    lineHeight: 1,
  },
  table: {
    maxWidth: 500,
  },
  modelChart: {
    height: "300px",
    width: "100%",
    maxWidth: "400px",
  },
  modelTag: {
    paddingRight: "1em",
  },
  modelTagContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: "4em",
  },

  text: {
    fontSize: "1.2em",
  },
  okIcon: {
    color: theme.palette.primary.main,
    "&> svg": {
      fontSize: "10em",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    fontSize: 15,
    width: "100%",
    flexWrap: "wrap",
    marginTop: "1em",
  },
}))

export default function SpectralSourceCreate() {
  const classes = useStyles()
  const { API_URL } = useConfig()
  const { sourceAdded } = useOnboardingState()
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [userError, setUserError] = useState<string | null>(null)
  const [serverError, setServerError] = useState<boolean>(false)
  const [sourceName, setSourceName] = useState<string>("Ma Source")
  const apiExceptionHandler = useApiExceptionHandler()
  const history = useHistory()
  const { t } = useTranslation()
  const { jwt } = useAuth()
  const [SuccessMessage, showSuccessMessage] = useSuccessMessage({
    message: "Fichier importé avec succès",
  })

  return (
    <PageContainer title="Import Spectral Data Source">
      <AppHeader color={true} openable={true} />
      <SideMenu />
      <PageContent padding={true}>
        <div style={{ maxWidth: 900, margin: "auto" }}>
          <SuccessMessage />
          <>
            {!serverError ? (
              <PageBlock titleInContent={true}>
                <strong className={classes.inputContainer}>Configuration {t("source.aname")}</strong>

                <div
                  style={{
                    maxWidth: "600px",
                    margin: "auto",
                    marginBottom: "2em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end" }}>
                    <Input
                      disabled={loading}
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      inputProps={{
                        multiple: false,
                        accept: "application/json",
                      }}
                      onChange={async (evt) => {
                        // @ts-ignore
                        const inputFile = evt.target.files.length > 0 ? evt.target.files[0] : null
                        setFile(inputFile)
                      }}
                    />
                    <label htmlFor="raised-button-file">
                      <AppButton
                        style={{ marginLeft: "1em" }}
                        submitting={loading}
                        color="primary"
                        variant="contained"
                        component="span"
                        startIcon={loading ? <Loader /> : undefined}
                      >
                        Charger un fichier de données au format JSON
                      </AppButton>
                    </label>
                  </div>
                  <div className={classes.inputContainer}>
                    <TextField
                      style={{ flexGrow: 1 }}
                      label={`Nom ${t("source.aname")}`}
                      variant="outlined"
                      value={sourceName}
                      margin="normal"
                      required
                      name="name"
                      onChange={(v) => setSourceName(v.target.value)}
                    />
                  </div>
                </div>
                {userError && (
                  <FormHelperText error={true} style={{ fontSize: "1em", width: "100%", textAlign: "center" }}>
                    {userError}
                  </FormHelperText>
                )}

                <div
                  style={{
                    marginTop: "1em",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <AppButton
                    submitting={loading}
                    onClick={async () => {
                      if (!file) {
                        alert("File is not defined")
                        return
                      }
                      setLoading(true)
                      try {
                        const res = await uploadFile(file, {
                          jwt,
                          url: API_URL + `/v1/import-data/create-spectral-source/` + sourceName,
                        })

                        if (res.statusCode && res.statusCode === 429) {
                          apiExceptionHandler(res)
                          setLoading(false)
                        } else if (res.statusCode && res.statusCode === 400) {
                          const userErr = extractConstraints(res)
                          if (userErr !== null) {
                            setUserError(Object.values(userErr).join(". "))
                          } else {
                            setServerError(true)
                          }

                          setLoading(false)
                        } else if (res.statusCode && res.statusCode === 500) {
                          setServerError(true)
                          setLoading(false)
                        } else {
                          showSuccessMessage()
                          sourceAdded()
                          setTimeout(() => {
                            history.push(
                              buildUrl({
                                route: AppRoute.SPECTRAL_DATA_SOURCE_LIST,
                              }),
                            )
                          }, 1000)
                        }
                      } catch (e) {
                        setLoading(false)
                      }
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Importer les données
                  </AppButton>
                </div>
              </PageBlock>
            ) : (
              <div>Error</div>
            )}
          </>
        </div>
      </PageContent>
    </PageContainer>
  )
}
