import React from "react"
import * as lodash from "lodash"
import { Field } from "react-final-form"
import { CustomFieldRenderProps } from "./decorator"
import { AnyChildren } from "../../react-type-helpers"
import { FormHelperText } from "@material-ui/core"
import { IndividuSelectTable } from "component/IndividuSelectTable"

export interface IndividuSelectTableFormState {
  sourceId: number
  selectedIndividuIds: number[]
}
interface IndividuTableSelectFieldProps {
  title: AnyChildren
  pageSize?: number
}

export function createIndividuSelectTableField<FormState>(
  props: { name: keyof FormState & string } & IndividuTableSelectFieldProps,
) {
  // @ts-ignore
  return <Field {...props} render={IndividuSelectTableFieldRender} />
}

/**
 * Use a memoized wrapper to avoid frequent re-render
 */
const IndividuSelectTableFieldRender = lodash.memoize(
  (props: CustomFieldRenderProps<IndividuSelectTableFormState, IndividuTableSelectFieldProps>) => {
    return (
      <div>
        <IndividuSelectTable
          key={props.input.value.sourceId}
          sourceId={props.input.value.sourceId}
          selectedIndividuIds={props.input.value.selectedIndividuIds}
          title={props.title}
          pageSize={props.pageSize}
          onChange={(selectedIndividus) =>
            props.input.onChange({
              ...props.input.value,
              selectedIndividuIds: selectedIndividus.map((s) => s.id),
            })
          }
        />
        {props.meta.error &&
          (Array.isArray(props.meta.error) ? props.meta.error : [props.meta.error]).map((e, i) => (
            <FormHelperText key={i} error={true}>
              {e}
            </FormHelperText>
          ))}
      </div>
    )
  },
  (props: CustomFieldRenderProps<IndividuSelectTableFormState, IndividuTableSelectFieldProps>) => props.input.value,
)
