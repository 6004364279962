/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
/**
 *
 * @export
 * @interface PredictionTaskInputs
 */
export interface PredictionTaskInputs {
  /**
   *
   * @type {string}
   * @memberof PredictionTaskInputs
   */
  title: string
  /**
   *
   * @type {Array<number>}
   * @memberof PredictionTaskInputs
   */
  individuIds: Array<number>
  /**
   *
   * @type {number}
   * @memberof PredictionTaskInputs
   */
  modelId: number
}

export function PredictionTaskInputsFromJSON(json: any): PredictionTaskInputs {
  return PredictionTaskInputsFromJSONTyped(json, false)
}

export function PredictionTaskInputsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTaskInputs {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: json["title"],
    individuIds: json["individuIds"],
    modelId: json["modelId"],
  }
}

export function PredictionTaskInputsToJSON(value?: PredictionTaskInputs | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    individuIds: value.individuIds,
    modelId: value.modelId,
  }
}
