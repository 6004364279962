/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime"
import {
  FeatureExtractionMethod,
  FeatureExtractionMethodFromJSON,
  FeatureExtractionMethodFromJSONTyped,
  FeatureExtractionMethodToJSON,
  MachineLearningMethod,
  MachineLearningMethodFromJSON,
  MachineLearningMethodFromJSONTyped,
  MachineLearningMethodToJSON,
  TaskStatus,
  TaskStatusFromJSON,
  TaskStatusFromJSONTyped,
  TaskStatusToJSON,
} from "./"

/**
 *
 * @export
 * @interface ModelTask
 */
export interface ModelTask {
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  readonly id: number
  /**
   *
   * @type {Date}
   * @memberof ModelTask
   */
  readonly createdDate: Date
  /**
   *
   * @type {Date}
   * @memberof ModelTask
   */
  readonly updatedDate: Date
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  readonly createdById: number
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  readonly updatedById: number
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  readonly companyId: number
  /**
   *
   * @type {string}
   * @memberof ModelTask
   */
  taskType: string
  /**
   *
   * @type {TaskStatus}
   * @memberof ModelTask
   */
  status: TaskStatus
  /**
   *
   * @type {Date}
   * @memberof ModelTask
   */
  readonly startDate: Date | null
  /**
   *
   * @type {Date}
   * @memberof ModelTask
   */
  readonly endDate: Date | null
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  readonly durationMs: number | null
  /**
   *
   * @type {string}
   * @memberof ModelTask
   */
  error: string | null
  /**
   *
   * @type {string}
   * @memberof ModelTask
   */
  title: string
  /**
   *
   * @type {MachineLearningMethod}
   * @memberof ModelTask
   */
  machineLearningMethod: MachineLearningMethod
  /**
   *
   * @type {FeatureExtractionMethod}
   * @memberof ModelTask
   */
  featureExtractionMethod: FeatureExtractionMethod
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  scoref1: number | null
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  trueNegatives: number | null
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  falseNegatives: number | null
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  truePositives: number | null
  /**
   *
   * @type {number}
   * @memberof ModelTask
   */
  falsePositives: number | null
  /**
   *
   * @type {string}
   * @memberof ModelTask
   */
  modelPath: string | null
}

export function ModelTaskFromJSON(json: any): ModelTask {
  return ModelTaskFromJSONTyped(json, false)
}

export function ModelTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelTask {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json["id"],
    createdDate: new Date(json["createdDate"]),
    updatedDate: new Date(json["updatedDate"]),
    createdById: json["createdById"],
    updatedById: json["updatedById"],
    companyId: json["companyId"],
    taskType: json["taskType"],
    status: TaskStatusFromJSON(json["status"]),
    startDate: json["startDate"] === null ? null : new Date(json["startDate"]),
    endDate: json["endDate"] === null ? null : new Date(json["endDate"]),
    durationMs: json["durationMs"],
    error: json["error"],
    title: json["title"],
    machineLearningMethod: MachineLearningMethodFromJSON(json["machineLearningMethod"]),
    featureExtractionMethod: FeatureExtractionMethodFromJSON(json["featureExtractionMethod"]),
    scoref1: json["scoref1"],
    trueNegatives: json["trueNegatives"],
    falseNegatives: json["falseNegatives"],
    truePositives: json["truePositives"],
    falsePositives: json["falsePositives"],
    modelPath: json["modelPath"],
  }
}

export function ModelTaskToJSON(value?: ModelTask | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    taskType: value.taskType,
    status: TaskStatusToJSON(value.status),
    error: value.error,
    title: value.title,
    machineLearningMethod: MachineLearningMethodToJSON(value.machineLearningMethod),
    featureExtractionMethod: FeatureExtractionMethodToJSON(value.featureExtractionMethod),
    scoref1: value.scoref1,
    trueNegatives: value.trueNegatives,
    falseNegatives: value.falseNegatives,
    truePositives: value.truePositives,
    falsePositives: value.falsePositives,
    modelPath: value.modelPath,
  }
}
