/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import { SpectralSource, SpectralSourceFromJSON, SpectralSourceToJSON } from "../models"

export interface ImportDataControllerCreateSpectralSourceRequest {
  sourceName: string
  requestBody: Array<string>
}

/**
 * no description
 */
export class ImportDataApi extends runtime.BaseAPI {
  /**
   * First spectral source creation using an json file
   */
  async importDataControllerCreateSpectralSourceRaw(
    requestParameters: ImportDataControllerCreateSpectralSourceRequest,
  ): Promise<runtime.ApiResponse<SpectralSource>> {
    if (requestParameters.sourceName === null || requestParameters.sourceName === undefined) {
      throw new runtime.RequiredError(
        "sourceName",
        "Required parameter requestParameters.sourceName was null or undefined when calling importDataControllerCreateSpectralSource.",
      )
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling importDataControllerCreateSpectralSource.",
      )
    }

    const queryParameters: runtime.HTTPQuery = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters["Content-Type"] = "application/json"

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = typeof token === "function" ? token("bearer", []) : token

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request({
      path: `/v1/import-data/create-spectral-source/{sourceName}`.replace(
        `{${"sourceName"}}`,
        encodeURIComponent(String(requestParameters.sourceName)),
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.requestBody,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) => SpectralSourceFromJSON(jsonValue))
  }

  /**
   * First spectral source creation using an json file
   */
  async importDataControllerCreateSpectralSource(
    requestParameters: ImportDataControllerCreateSpectralSourceRequest,
  ): Promise<SpectralSource> {
    const response = await this.importDataControllerCreateSpectralSourceRaw(requestParameters)
    return await response.value()
  }
}
