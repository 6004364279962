/* tslint:disable */
/* eslint-disable */
/**
 * timelight
 * This is the timelight api.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FeatureExtractionMethod {
  FFT5Bandes = "FFT 5 bandes",
  ContinuousWaveletTransform = "Continuous Wavelet Transform",
  DiscreteWaveletTransform = "Discrete Wavelet Transform",
}

export function FeatureExtractionMethodFromJSON(json: any): FeatureExtractionMethod {
  return FeatureExtractionMethodFromJSONTyped(json, false)
}

export function FeatureExtractionMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureExtractionMethod {
  return json as FeatureExtractionMethod
}

export function FeatureExtractionMethodToJSON(value?: FeatureExtractionMethod | null): any {
  return value as any
}
